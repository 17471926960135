import { React } from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as ThumbUpIcon } from '../../assets/images/hand-thumb-up.svg';
import { ReactComponent as ThumbDownIcon } from '../../assets/images/hand-thumb-down.svg';

const ChatbotFeedback = (props) => {
    const handleSelectFeedbackOption = (option) => {
        if (props.selectedFeedbackOption === option) {
            props.setSelectedFeedbackOption("close_chat");
        } else {
            props.setSelectedFeedbackOption(option);
        }
    };
    return (
        <Box>
            <Typography className='chatbot-feedback-box'>
                <Typography className='f-20 font-weight-600 gray11'>Feedback</Typography>
                <Typography className='f-16 font-weight-400 gray11'>Overall, how would you rate your chat experience?</Typography>
                <Grid container lg={12} md={12} xs={12} className='display-flex align-center mt-40 mb-20'>
                    <Grid item lg={6} md={6} xs={12}>
                        <Typography className='text-center'>
                            <ThumbUpIcon
                                width="100"
                                height="100"
                                onClick={() => handleSelectFeedbackOption("close_chat_with_thumbs_up")}
                                aria-label="like"
                                className={`cursor-hover ${props.selectedFeedbackOption == "close_chat_with_thumbs_up" ? "chat-feedback-selected-icon" : "chat-feedback-icon"}`}
                            />
                        </Typography>
                        <Typography className='f-14 font-weight-400 gray11 text-center'>
                            <span className='cursor-hover'
                                onClick={() => handleSelectFeedbackOption("close_chat_with_thumbs_up")}
                            >
                                Like
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Typography className='text-center'>
                            <ThumbDownIcon
                                width="100"
                                height="100"
                                onClick={() => handleSelectFeedbackOption("close_chat_with_thumbs_down")}
                                aria-label="dislike"
                                className={`cursor-hover ${props.selectedFeedbackOption == "close_chat_with_thumbs_down" ? "chat-feedback-selected-icon" : "chat-feedback-icon"}`}
                            />
                        </Typography>
                        <Typography className='f-14 font-weight-400 gray11 text-center'>
                            <span className='cursor-hover'
                                onClick={() => handleSelectFeedbackOption("close_chat_with_thumbs_up")}
                            >
                                Dislike
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container lg={12} md={12} xs={12} className='display-flex justify-center mt-40'>
                    {/* <Typography className={`f-14 font-weight-600 ${(props.selectedFeedbackOption === "close_chat_with_thumbs_up" || props.selectedFeedbackOption === "close_chat_with_thumbs_down") ? "chatbot-feedback-button cursor-hover" : "chatbot-feedback-disabled-button cursor-default"}`}
                        onClick={() => {
                            if (props.selectedFeedbackOption === "close_chat_with_thumbs_up" || props.selectedFeedbackOption === "close_chat_with_thumbs_down") {
                                props.callBlipService(props.selectedFeedbackOption, null, props.getCookie("user_session"));
                            }
                        }}
                    >
                        Submit
                    </Typography> */}
                    <Typography className='f-14 cursor-hover font-weight-600 chatbot-feedback-button'
                        onClick={() => props.callBlipService(props.selectedFeedbackOption, null, props.getCookie("user_session"))}
                    >
                        Close
                    </Typography>
                </Grid>
            </Typography>
        </Box>
    );
};
export default ChatbotFeedback;

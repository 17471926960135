import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiCalls from "../../../api/customAxios";
import { getCurrentUserToken } from "../../../helpers/helper";
import { ReactComponent as JobListIcon } from "./../../../assets/images/global-search-jobList.svg";
import { ReactComponent as SkillIcon } from "./../../../assets/images/global-search-skills.svg";
import { useSelector } from "react-redux";
import KeycloakService from "../../../keycloak";

export default function SearchFilter(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  const {
    query,
    setJobTitleFilterValue,
    setShowFiltersDropdown,
    setKeywordSearch,
  } = props;
  const location = useLocation();
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("name");
  let navigate = useNavigate();
  const [jobs, setJobs] = React.useState({
    pageNumber: 0,
    jobs: [],
  });
  const [hideNoResultDropdown, setHideNoResultDropdown] = React.useState(false);
  const [getUserToken, setUserToken] = React.useState(null);
  const isAuthenticated = KeycloakService.isLoggedIn();

  useEffect(() => {
    let isUserAuthenticated = getCurrentUserToken();
    isUserAuthenticated.then(async (res) => {
      await setUserToken(res);
    });
  }, []);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowFiltersDropdown(false);
          setJobTitleFilterValue("");
          setKeywordSearch("");
          setHideNoResultDropdown("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const getStrings = (name, type) => {
    if (type === "S") {
      let skillSplit = name.split("-");
      return (
        <Typography>
          <span className="sub-text-font">{skillSplit[0]}</span>{" "}
          <span className="f-13 light-grey">
            [{skillSplit[1]} - {skillSplit[2]}]
          </span>
        </Typography>
      );
    }
    return name.toLowerCase();
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    if (name?.toLowerCase() !== query.searchBar.toLowerCase()) {
      let publickUrl = `${process.env.REACT_APP_API_URL.replace(
        "api/v1/",
        "public/api/v1/"
      )}candidates/search?keyword=${query.searchBar}${
        appOrigin === "H" ? "&is-healthcare=true" : ""
      }`;
      let privateUrl = `${
        process.env.REACT_APP_API_URL
      }candidates/search?keyword=${query.searchBar}${
        appOrigin === "H" ? "&is-healthcare=true" : ""
      }`;
      ApiCalls.get(isAuthenticated ? privateUrl : publickUrl).then(
        (response) => {
          // if (response.statusCode === 200) {
          setJobs({ pageNumber: 0, jobs: response });
          if (response.length <= 0) {
            setHideNoResultDropdown(true);
          } else {
            setHideNoResultDropdown(false);
          }
          // }
        }
      );
    }
  }, [query]);
  async function gotoDetails(element) {
    setJobTitleFilterValue("");
    props?.setCloseIconState(true);
    props.getSelectedRecode(element);
  }

  if (jobs.jobs.length <= 0 && hideNoResultDropdown) {
    return (
      <Grid
        ref={wrapperRef}
        style={{
          position: "absolute",
          width: "46%",
          zIndex: "99",
          // top: "70px",
        }}
        className="noResult"
      >
        <Grid
          container
          spacing={1}
          lg={12}
          md={12}
          xs={12}
          className="m-0 search-filter-align"
        >
          <Grid lg={12} md={12} xs={12} className="m-auto">
            <Typography className="searchFilter pb-15 p-t-0">
              <Typography className="">
                <Typography className="justify-center display-flex">
                  <Typography className="font-weight-500 f-14 pl-10 cursor-default gray9">
                    No results found
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  if (jobs.jobs.length > 0) {
    return (
      <>
        <Grid
          ref={wrapperRef}
          style={
            props.enableScroll
              ? {
                  position: "fixed",
                  width: "46%",
                  zIndex: "99",
                  // top: "70px",
                  // height: "75px",
                  overflowY: "auto",
                }
              : {
                  position: "absolute",
                  width: "46%",
                  zIndex: "99",
                }
          }
          className="noResult"
        >
          <Grid container spacing={1} lg={12} md={12} xs={12} className="m-0">
            <Grid lg={12} md={12} xs={12} className="m-auto">
              <Typography
                className="searchFilter search-filter-align"
                style={
                  props.enableScroll
                    ? {
                        padding: "10px 20px 0px 20px",
                        border: "0.5px solid #e4e7ec",
                      }
                    : {
                        padding: "10px 20px 0px 20px",
                      }
                }
              >
                {jobs.jobs.slice(0, 5).map((element, index) => (
                  <Box
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => gotoDetails(element)}
                    onKeyPress={() => gotoDetails(element)}
                    tabIndex={0}
                  >
                    <Typography className="py-15 borderBottom">
                      <Typography className="display-flex align-center">
                        <Typography className="font-weight-500 f-14 display-flex">
                          {element.type === "T" ? (
                            <JobListIcon
                              alt="img"
                              width="20px"
                              className="sub-text-color"
                              aria-label="Jobs icon"
                            />
                          ) : (
                            <SkillIcon
                              alt="img"
                              width="20px"
                              className="sub-text-color"
                              aria-label="Skill icon"
                            />
                          )}

                          <Typography className="pl-10 galaxy-global f-16">
                            {/* {element.name.toLowerCase()} */}
                            {/* {element.type === "S"? (element.name.split("-")):} */}
                            {getStrings(element.name, element.type)}
                          </Typography>

                          {element.type === "T" ? (
                            <span className="sub-text-color pl-10 f-12 p-t-5">
                              <i>in job title</i>
                            </span>
                          ) : (
                            <span className="sub-text-color pl-10 f-12 p-t-5">
                              <i>
                                {appOrigin === "D"
                                  ? "in skills"
                                  : "in specialization"}
                              </i>
                            </span>
                          )}
                        </Typography>
                      </Typography>
                    </Typography>
                  </Box>
                ))}
                {jobs.jobs.length > 5 && (
                  <Typography className="mt-10 justify-center display-flex">
                    <Button
                      onClick={() => {
                        setShowFiltersDropdown(false);
                        const type = `searchBar=${query.searchBar}`;
                        if (location.pathname.includes("find-jobs")) {
                          if (isAuthenticated) {
                            navigate(`/candidate/find-jobs?${type}`);
                          } else {
                            navigate(`/find-jobs?${type}`);
                          }
                        } else {
                          if (!isAuthenticated) {
                            navigate(`/job-list?${type}`);
                            return;
                          }
                          navigate(`/candidate/find-jobs?${type}`);
                        }
                      }}
                      disableRipple="true"
                      className="link-color f-14 font-weight-500 text-decoration-none"
                      style={{ textTransform: "none" }}
                    >
                      See All
                    </Button>
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  return <></>;
}

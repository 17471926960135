import { React, useState, useEffect } from "react";
import { Grid, Typography, Box, Button, MenuItem, Container } from "@mui/material";
import dsaasLogoNav from "../../assets/images/direct-healthcare-logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import Constants from "../../helpers/constants";
import { setPlatformTheme } from "../../helpers/helper-data";

export default function HealthCareNavbar(props) {
    const appOrigin = useSelector((state) => state.location.origin);
    const [careerPortalFavIcon, setCareerPortalFavIcon] = useState(null);
    useEffect(()=> {
        if (careerPortalFavIcon) {
            document
              .querySelector("link[title=icon]")
              .setAttribute("href", careerPortalFavIcon);
        } else if (appOrigin === "H") {
        document.querySelector("link[title=icon]").setAttribute("href", "/healthcare-favicon.png");
        }
    }, [careerPortalFavIcon]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const URLPath = location.pathname;
    let navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [domainNavBarLogoSignedUrl, setDomainNavBarLogoSignedUrl] = useState(null);
    useEffect(() => {
        let exclusiveReferral = queryParams.get("referralDetails") ? JSON.parse(atob(queryParams.get("referralDetails"))) : null;//referred candidate should be exclusive
        if (queryParams.get("paramsKey") || localStorage.getItem("domain") || exclusiveReferral?.validDomainName) {
        try {
        let domain = queryParams.get("paramsKey") ? JSON.parse(atob(queryParams.get("paramsKey")))?.validDomainNames : exclusiveReferral?.validDomainName ? exclusiveReferral?.validDomainName : localStorage.getItem("domain");
        localStorage.setItem("domain", domain);
        const publicEnterpriseDetailsUrl = `${process.env.REACT_APP_API_URL.replace(
            "api/v1/",
            "public/api/v1/"
        )}candidates/enterprise/details/${domain}`;
        ApiCalls.get(publicEnterpriseDetailsUrl)
        .then((res) => {
            localStorage.setItem("sourceEnterpriseId", res.enterpriseId);
            localStorage.setItem("domainEnterpriseId", res.enterpriseId);
            if (localStorage.getItem("pool_id")) {
                let pool = { "pool_id": localStorage.getItem("pool_id"), "id": res.enterpriseId, "ename": res.establishmentName };
                localStorage.setItem("poolFromPortal", JSON.stringify(pool));
                localStorage.removeItem("pool_id");
            }
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('referralDetails')) {
                const decodedString = atob(urlParams.get('referralDetails'));
                localStorage.setItem("referralDetails", decodedString);
            }
            res.exclusiveCandidateTheme ? setPlatformTheme(res.exclusiveCandidateTheme, appOrigin) : setPlatformTheme(res.theme, appOrigin);
            if (res.exclusiveCandidateLogoFileSignedUrl) {
                setDomainNavBarLogoSignedUrl(res.exclusiveCandidateLogoFileSignedUrl);
                localStorage.setItem("domainNavBarLogoSignedUrl", res.exclusiveCandidateLogoFileSignedUrl);
              } else if (res.navBarLogoSignedUrl)  {
                setDomainNavBarLogoSignedUrl(res.navBarLogoSignedUrl);
                localStorage.setItem("domainNavBarLogoSignedUrl", res.navBarLogoSignedUrl);
              }
            if (res.careerPortalFavIcon) {
                localStorage.setItem("careerPortalFavIcon", res.careerPortalFavIcon);
                setCareerPortalFavIcon(res.careerPortalFavIcon);
            }
        });
        } catch (err) {}
        }
    }, []);
    const handleActiveButtonHome = () => {
        navigate("/home");
        window.scrollTo( 0, 0 );
    };
    const handleActiveButtonFindJobs = () => {
        navigate("/job-list");
    };
    async function signup() {
        navigate("../signup");
    }
    const goToSignup = () => {
        navigate("../signup");
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = (event) => {
        setAnchorEl(null);
    };
    const handleScrollTestimonials = (e) => {
        if (URLPath === "/" || URLPath === "/home") {
            props.testimonialRef?.current?.scrollIntoView();
        } else {
            navigate("../home", { state: { scrollToTestimonials: true } });
        }
    };
    return (
        <>
            <Grid>
                {isMobile ? (
                    <Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <Grid className="healthcare-navbar">
                                <Grid container spacing={1} lg={12} md={12} xs={12} className="m-0 align-center display-flex pl-10 pr-10">
                                    <Grid item lg={7} md={7} xs={7} className="p-0">
                                        <Link to="/" className="fastpass-issue">
                                            <Box className="display-flex align-center">
                                                <img
                                                    src={domainNavBarLogoSignedUrl ? domainNavBarLogoSignedUrl : dsaasLogoNav}
                                                    alt="Logo"
                                                    width="130px"
                                                    height="50px"
                                                />
                                            </Box>
                                        </Link>
                                    </Grid>
                                    <Grid item lg={3} md={3} xs={3} className="p-0">
                                        <Link to="../signin"
                                            className="hc-primary-text f-18 font-weight-400 text-decoration-none">
                                            Login
                                        </Link>
                                    </Grid>
                                    <Grid item lg={2} md={2} xs={2} className="justify-end display-flex p-0">
                                        <Typography className="align-center display-flex">
                                            <Button className="p-r-0 justify-end display-flex" variant="text" onClick={handleMenu} role="switch" tabIndex={0} disableRipple="true">
                                                <MenuIcon className="hc-primary-text f-25" aria-label="Menu icon" />
                                            </Button>
                                        </Typography>
                                        <Menu
                                            className="navbar-responsive sidebar-dropdown"
                                            id="menu-appbar"
                                            disableScrollLock={true}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                            Keepmounted
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                            open={open}
                                        >
                                            <Grid
                                                container
                                                item
                                                lg={6}
                                                md={6}
                                                className="justify-end align-center navbar-responsive"
                                                sx={{ mt: 7 }}
                                            >
                                                <Box>
                                                    <Box className="align-right">
                                                        <CloseIcon
                                                            className="f-25 font-weight-600 p-3px cursor-hover sub-text-color"
                                                            onClick={handleCloseMenu}
                                                            aria-label="Close icon"
                                                            tabIndex={0}
                                                            onKeyPress={handleCloseMenu}
                                                        />
                                                    </Box>
                                                    <Grid className="mt-15">
                                                        <Grid>
                                                            <Button
                                                                className={
                                                                    (((URLPath === "/") || (URLPath === "/home")) && !props.activeTestimonials)
                                                                        ? "btn active-text-header"
                                                                        : "btn text-header"
                                                                }
                                                                onClick={() => handleActiveButtonHome()}
                                                                tabIndex={0}
                                                                disableRipple="true"
                                                            >
                                                                Home
                                                            </Button>
                                                        </Grid>
                                                        <Grid className="mt-10">
                                                            <Button
                                                                className={
                                                                    URLPath === "/job-list"
                                                                        ? "btn active-text-header"
                                                                        : "btn text-header"
                                                                }
                                                                onClick={() => handleActiveButtonFindJobs()}
                                                                tabIndex={0}
                                                                disableRipple="true"
                                                            >
                                                                Browse Jobs
                                                            </Button></Grid>
                                                        <Grid className="mt-10">
                                                            <Button
                                                                className={
                                                                    props.activeTestimonials
                                                                        ? "btn active-text-header"
                                                                        : "btn text-header"
                                                                }
                                                                tabIndex={0}
                                                                disableRipple="true"
                                                                onClick={handleScrollTestimonials}
                                                            >
                                                                Testimonials
                                                            </Button>
                                                        </Grid>
                                                        <Grid className="mt-50">
                                                            <Button className="btn healthcare-navbar-btn">Join {Constants.DIRECT_HEALTH}</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Menu>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid className="healthcare-navbar">
                        <Grid container spacing={1} className="body-container" sx={{ px: 4 }}>
                        <Grid container spacing={1} lg={12} md={12} xs={12}>
                            <Grid item lg={4} md={4} xs={12}>
                                <Link to="/" className="fastpass-issue">
                                    <Box >
                                        <img
                                            src={domainNavBarLogoSignedUrl ? domainNavBarLogoSignedUrl : dsaasLogoNav}
                                            alt="Logo"
                                            // width="150px"
                                            // height="50px"
                                        />
                                    </Box>
                                </Link>
                            </Grid>
                            <Grid item lg={8} md={8} xs={12} className="display-flex justify-end">
                                <Grid className="display-flex align-center">
                                    <Button
                                        className={
                                            (((URLPath === "/") || (URLPath === "/home")) && !props.activeTestimonials)
                                                ? "btn active-text-header"
                                                : "btn text-header"
                                        }
                                        onClick={() => handleActiveButtonHome()}
                                        tabIndex={0}
                                        disableRipple="true"
                                    >
                                        Home
                                    </Button>
                                    <Button
                                        className={
                                            URLPath === "/job-list"
                                                ? "btn active-text-header"
                                                : "btn text-header"
                                        }
                                        onClick={() => handleActiveButtonFindJobs()}
                                        tabIndex={0}
                                        disableRipple="true"
                                        sx={{ ml: 5 }}
                                    >
                                        Browse Jobs
                                    </Button>
                                    <Button
                                        className={
                                            props.activeTestimonials
                                                ? "btn active-text-header"
                                                : "btn text-header"
                                        }
                                        //   onClick={() => handleActiveButtonHome()}
                                        tabIndex={0}
                                        disableRipple="true"
                                        sx={{ ml: 5, mr: 5 }}
                                        onClick={handleScrollTestimonials}
                                    >
                                        Testimonials
                                    </Button>
                                    <Link to="../signin"
                                        className="hc-primary-text f-18 font-weight-400 text-decoration-none">
                                        Login
                                    </Link>
                                    <Grid onClick={goToSignup}>
                                        <Button className="btn healthcare-navbar-btn" sx={{ ml: 5 }}>Join {Constants.DIRECT_HEALTH}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
}


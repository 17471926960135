import React, { useEffect, useState, useRef } from "react";
import { Typography, Grid, Container, Select, Checkbox, FormControlLabel, Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Box from "@mui/material/Box";
import { ReactComponent as AnalyticsIcon } from '../../../assets/images/analytics-black-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router-dom';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import { useSelector } from "react-redux";

export default function AnalyticsSideBar(props) {
  let navigate = useNavigate();
  const appOrigin = useSelector((state) => state.location.origin);
  const location = useLocation();
  const URLPath = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const [option, setOption] = useState(null);
  useEffect(()=>{
    let keyword = searchParams.get("keyword");
    if (keyword === "my-average-rating") {
      setOption(1);
    } else if (keyword === "my-activity-summary") {
      setOption(2);
    } else if (keyword === "job-density") {
      setOption(3);
    } else {
      setOption(0);
    }
  }, []);
  const handleCloseAnalyticsSideBar = () => {
    props.setOpenAnalytics(false);
  };
  // const handleCloseMenu = (event) => {
  //   props.setOpenAnalytics(false);
  // };

  const goToReport = (report, option, title) => {
    // handleCloseMenu();
    setOption(option);
    navigate('/candidate/analytics', { state: { report: report, option: option, title: title } });
    props?.handleCloseMenu();
  };
  const [anchorE4, setAnchorE4] = useState(null);
  const open4 = Boolean(anchorE4);
  const handleClick4 = (event) => {
    if (URLPath.includes("/candidate/analytics")) {
      setOption(option ? option : 0);
    } else {
      setOption(null);
    }
    setAnchorE4(event.currentTarget);
    // setIsActive("Assessments");
  };
  const handleClose4 = () => {
    setAnchorE4(null);
  };
  return (
    <Grid
      className=""
    >

      <Button
        id="basic-button-menu"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        // aria-controls="basic-menu2"
        //className="dropdown-arrow text-transform-none cursor-hover"
        style={{ padding: "6px 8px" }}
        onClick={handleClick4}
        role="switch"
        aria-checked={open ? "true" : "false"}
        tabIndex={0}
        disableRipple="true"
        className={
          URLPath.includes("/candidate/analytics")
            ? "dropdown-arrow text-transform-none cursor-hover btn activebutton-header infoFont profile-btn"
            : "btn button-header  infoFont profile-btn report-btn"
        }
      >
        Reports
        <ExpandMoreIcon
          className="cursor-hover headerIcon inputcontrasts"
          aria-label="Dropdown icon"
        />
      </Button>
      <Menu
        style={{ top: "5px" }}
        id="account-menu"
        anchorEl={anchorE4}
        open={open4}
        className="cursor-hover analytics-menu"
        onClose={handleClose4}
        onClick={handleClose4}
        disableScrollLock={true}
        onKeyPress={handleClose4}
        MenuListProps={{
          "aria-labelledby": "basic-button-menu",
        }}
        Keepmounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          className={option === 0 ? "analytics small-text-font analytics-active-color p-10" : "small-text-font analytic-res-align  p-10"}
          onClick={() =>
            goToReport(process.env.REACT_APP_REPORTS_CANDIDATE_DASHBOARD, 0, "My Dashboard")
          }
        >
          <span className="p-t-2 px-15 f-14">My Dashboard</span>
        </MenuItem>
        <MenuItem
          className={option === 1 ? "analytics small-text-font analytics-active-color  p-10" : "small-text-font analytic-res-align  p-10"}
          onClick={() =>
            goToReport(process.env.REACT_APP_REPORTS_CANDIDATE_AVERAGE_RATING, 1, "My Average Rating")
          }
        >
          <span className="p-t-2 px-15 f-14">My Average Rating</span>
        </MenuItem>
        <MenuItem
          className={option === 2 ? "analytics small-text-font analytics-active-color  p-10" : "small-text-font analytic-res-align  p-10"}
          onClick={() =>
            goToReport(process.env.REACT_APP_REPORTS_CANDIDATE_ACTIVITY_SUMMARY, 2, "My Activity Summary")
          }
        >
          <span className="p-t-2 px-15 f-14">My Activity Summary</span>
        </MenuItem>
        {/* {appOrigin != "N" && ( */}
          <MenuItem
            className={option === 3 ? "analytics small-text-font analytics-active-color  p-10" : "small-text-font analytic-res-align  p-10"}
            onClick={() =>
              goToReport(process.env.REACT_APP_REPORTS_JOB_DENSITY, 3, "Job Density")
            }
          >
            <span className="p-t-2 px-15 f-14">Job Density</span>
          </MenuItem>
        {/* )} */}
      </Menu>

      {/* <Grid className="display-flex justify-between pl-15 pr-20 pt-10">
        <Typography className="display-flex align-center">
          <AnalyticsIcon width="16px" height="16px" aria-label="Analytics icon" />
          <Box className="font-weight-600 f-14 ml-10" style={{ paddingTop: '2px' }}>Analytics</Box>
        </Typography>
        <Typography>
          <CloseIcon className="f-16 sub-text-color cursor-hover" onClick={handleCloseAnalyticsSideBar} onKeyPress={handleCloseAnalyticsSideBar} aria-label="Close icon" tabIndex={0} />
        </Typography>
      </Grid>
      <List sx={{ mt: 7 }}>
        <ListItem className="p-0">
          <ListItemButton
            className={option === 0 ? "analytics p-15 small-text-font justify-center" : "p-15 small-text-font analytic-res-align"}
            onClick={() =>
              goToReport(process.env.REACT_APP_REPORTS_CANDIDATE_DASHBOARD, 0, "My Dashboard")
            }
            disableRipple="true"
          >
            <Typography variant="inherit">My Dashboard</Typography>
          </ListItemButton>
        </ListItem>

        <ListItem className="p-0">
          <ListItemButton
            className={option === 1 ? "analytics p-15 small-text-font justify-center" : "p-15 small-text-font analytic-res-align"}
            onClick={() =>
              goToReport(process.env.REACT_APP_REPORTS_CANDIDATE_AVERAGE_RATING, 1, "My Average Rating")
            }
            disableRipple="true"
          >
            <Typography variant="inherit">My Average Rating</Typography>
          </ListItemButton>
        </ListItem>
        <ListItem className="p-0">
          <ListItemButton
            className={option === 2 ? "analytics p-15 small-text-font justify-center" : "p-15 small-text-font analytic-res-align"}
            onClick={() =>
              goToReport(process.env.REACT_APP_REPORTS_CANDIDATE_ACTIVITY_SUMMARY, 2, "My Activity Summary")
            }
            disableRipple="true"
          >
            <Typography variant="inherit">My Activity Summary</Typography>
          </ListItemButton>
        </ListItem>
        <ListItem className="p-0">
          <ListItemButton
            className={option === 3 ? "analytics p-15 small-text-font justify-center" : "p-15 small-text-font analytic-res-align"}
            onClick={() =>
              goToReport(process.env.REACT_APP_REPORTS_JOB_DENSITY, 3, "Job Density")
            }
            disableRipple="true"
          >
            <Typography variant="inherit">Job Density</Typography>
          </ListItemButton>
        </ListItem>
      </List> */}
    </Grid>
  );
}

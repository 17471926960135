import { combineReducers } from "@reduxjs/toolkit";
import profileReducer from "./Profile/profile";
import appOriginReducer from "./appOriginReducer";
import pageNotFoundReducer from "./pageNotFoundReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const rootReducers = combineReducers({
  profile: profileReducer,
  location: appOriginReducer,
  pageNotFound: pageNotFoundReducer,
});
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);
export default persistedReducer;

import { toast } from "react-toastify";

export const success = (message) => {
  toast.dismiss();
  toast.success(message);
};

export const danger = (message) => {
  toast.dismiss();
  toast.error(message);
};

export const warning = (message) => {
  toast.dismiss();
  toast.warning(message);
};

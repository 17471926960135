
import React, { Component } from "react";
import {
    Button,
    Grid,
    Typography,
  } from "@mui/material";
  import error from "./assets/images/Something went wrong page.svg";
import axios from "axios";

class ErrorBoundary extends Component {
    state = {
        hasError: false,
        isAuthenticated: localStorage.getItem("token"),
    };

    componentDidCatch(error, info) {
        const errorLog = [];
        errorLog.push({ error: error.toString(), errorInfo: info.componentStack, timestamp: new Date() });
        axios.post(`${process.env.REACT_APP_ANALYTICS_EXPORT}/public/consolelog`, JSON.stringify(errorLog), {
            headers: {
                'Content-Type': 'application/json'
              }
        })
        .then(async(res) => {
        });
        if (error) {
            if (String(error).toLowerCase().includes("chunk")) {
                window.location.reload();
            } else {
                this.setState((state) => ({ ...state, hasError: true }));
            }
        } else {
            this.setState((state) => ({ ...state, hasError: false }));
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Grid container spacing={1} item lg={12} md={12} xs={12}>
                    <Grid
                        item lg={4} md={4} xs={12}
                        className="m-auto text-center">
                        <Typography sx={{ mt: 10 }}>
                            <img src={error} alt="something went wrong" width="300px" height="300px"></img>
                            <Typography className="mt-30 mb-30 f-25 font-weight-600 light-text">Something went wrong</Typography>
                            <Button class="btn primary-button pt-10 pb-10" onClick={(e) => (window.location.href = this.state.isAuthenticated ? "/candidate/dashboard" : "/")}
                                tabindex={0} type="button" disableRipple="true">Back to Home Page</Button>
                        </Typography>
                    </Grid>
                </Grid>
            );
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;


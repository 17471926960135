module.exports = {
  APP_NAME: "SimplifyHire",
  DIRECT_HEALTH: "Direct Health",
  NEURODIVERSIFY: "Neurodiversify",
  UserNotFoundException: "UserNotFoundException",
  SUCCESS: "SUCCESS",
  CODE_MISMATCH_EXCEPTION: "CodeMismatchException",
  LIMIT_EXCEEDED_EXCEPTION: "LimitExceededException",
  NOT_AUTHORIZED_EXCEPTION: "NotAuthorizedException",
  USER_NOT_CONFIRMED_EXCEPTION: "UserNotConfirmedException",
  INVALID_PARAMETER_EXCEPTION: "InvalidParameterException",
  USERNAME_EXISTS_EXCEPTION: "UsernameExistsException",
  ACCOUNT_NOT_ACTIVE: "UserLambdaValidationException",
  UNEXPECTED_LAMBDA_EXCEPTION: "UnexpectedLambdaException",
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  DEFAULT_BACKGROUND_IMAGE_URL:
    "https://daas-prog-test.s3.amazonaws.com/background-image.png",
  DEFAULT_PROFILE_IMAGE_URL:
    "https://daas-prog-test.s3.amazonaws.com/default-user.png",
  DEFAULT_ENTERPRISE_LOGO_URL:
    "https://daas-prog-test.s3.amazonaws.com/default-logo.png",
  DEFAULT_GENERAL_LOGO_URL:
  "https://daas-prog-test.s3.amazonaws.com/app_logo_general.png",
  DEFAULT_NEURODIVERSIFY_LOGO_URL:
  "https://daas-prog-test.s3.amazonaws.com/app_logo_neurodiversify.png",
  DEFAULT_HEALTHCARE_LOG0_URL:
  "https://daas-prog-test.s3.amazonaws.com/app_logo_healthcare.png",
  DEFAULT_GENERAL_LOGO_WHITE_URL:
  "https://daas-prog-test.s3.amazonaws.com/app_logo_general_white.png",
  APPLY_VIDEO_PITCH_URL:
  "https://daas-prog-test.s3.amazonaws.com/watch-example.mp4",
  DEFAULT_QUESTIONS: [
    {
      Question: "From when are you available for this job?",
      Options: [],
      Type: "date",
      default: true,
    },
    {
      Question: "Select your availability?",
      Options: ["Full Time", "Part Time"],
      Type: "radio",
      default: true,
    },
    {
      Question: "For how many hours are you available per day?",
      Type: "text",
      default: true,
    },
    {
      Question: "What days of the week you are available?",
      Options: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      Type: "checkbox",
      default: true,
    },
  ],
  CANDIDATE_SHOWCASE_FILE: "SHOWCASE",
  CANDIDATE_EDUCATION_FILE: "EDUCATION",
  CANDIDATE_PROFILE_FILE: "PROFILE",
  CANDIDATE_RESUME_FILE: "RESUME",
  CANDIDATE_OFFER_FILE: "CANDIDATE_OFFER",
  CANDIDATE_BACKGROUND_FILE: "BACKGROUND",
  DEBOUNCE_TIME: 300,
  Data_Incomplete: "(Data Incomplete)"
};

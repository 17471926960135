import { React } from 'react';
import { Box, Grid, Typography } from "@mui/material";

const LinkWidget = (props) => {
    const getCTAButtonTitle = (url) => {
        let arr = url?.includes("?") ? url?.split("?")[0].split("/") : url?.split("/");
        if (arr.length) {
            let end_point = url?.endsWith("/") ? arr[arr.length - 2] : arr[arr.length - 1];
            if (end_point?.includes("signin")) {
                return "Sign In";
            } else if (end_point?.includes("signup")) {
                return "Sign Up";
            } else if (end_point?.includes("forgotpassword")) {
                return "Sign In";
            } else if (end_point?.includes("message-list")) {
                return "Messages";
            } else if (getSearchParamKeyword()?.includes("feedback")) {
                return "Feedback";
            } else if (getSearchParamKeyword()?.includes("notifications")) {
                return "Notifications";
            }
            return end_point.replace("-", " ");
        }
        return null;
    };
    const intent_keyword_map = {
        "help_guide_profile_edit_portfolio": "?keyword=portfolio-links",
        "help_guide_profile_edit_work_ex": "?keyword=work-experience",
        "help_guide_profile_edit_education": "?keyword=education",
        "help_guide_profile_update_skill_certification_showcase": "?keyword=certifications",
        "help_guide_certifications": "?keyword=certifications",
        "help_guide_lastused_skills": "?keyword=skills",
        "help_guide_profile_edit_resume": "?keyword=resume",
        "help_guide_resume": "?keyword=resume",
        "help_guide_generate_profile_summary": "?keyword=profile-summary",
        "help_guide_ratings": "?keyword=reviews",
        "help_guide_reviews_ratings": "?keyword=reviews",
        "help_guide_add_references": "?keyword=references",
        "help_guide_add_reference_profile": "?keyword=references",
        "help_guide_licensure": "?keyword=licensure",
        "help_guide_my_jobs_applied": "?tabValue=Applied",
        "help_guide_withdraw_application": "?tabValue=Applied",
        "applied_status": "?tabValue=Applied",
        "help_guide_offer": "?tabValue=Offered",
        "offer_acceptance_next_steps_after_job_offer": "?tabValue=Offered",
        "help_guide_onboard_jobs": "?tabValue=Onboarded",
        "help_guide_offboarded_jobs": "?tabValue=Offboarded",
        "help_guide_update_phone_number": "?keyword=contact-settings",
        "help_guide_unbind_request": "?keyword=manage-account",
        "help_guide_delete_account": "?keyword=manage-account",
        "help_guide_inactivate_account": "?keyword=manage-account",
        "help_guide_anonymize_account": "?keyword=manage-account",
        "help_guide_change_password": "?keyword=password-security",
        "help_guide_update_email_address": "?keyword=contact-settings",
        "help_guide_feedback_icon": "?keyword=feedback",
        "help_guide_send_feedback": "?keyword=feedback",
        "help_guide_see_notification": "?keyword=notifications",
        "help_guide_reports_my_average_rating": "?keyword=my-average-rating",
        "help_guide_reports_my_dashboard": "?keyword=my-dashboard",
        "help_guide_reports_my_activity_summary": "?keyword=my-activity-summary",
        "help_guide_active_summary_report": "?keyword=my-activity-summary",
        "help_guide_reports_job_density": "?keyword=job-density",
    };
    const getSearchParamKeyword = () => {
        if (props.intent) {
            try {
                let intent = props.intent.trim().split(" ")[0].split(":")[1];
                if (intent_keyword_map[intent]) {
                    return intent_keyword_map[intent];
                }
            } catch (err) {}
        }
        return "";
    };
    return (
        // <Box>
        // <Typography className='chatbot-password-box'>
        //     <Typography className='f-14 font-weight-400 gray9'>
        //         {props.caption}
        //     </Typography>
        //     <Typography className={`${props.caption ? 'm-t-5' : ''}`}>
        //         <a href={props.linkUrl} rel="noreferrer" target="_blank" className='f-16 font-weight-500 gray9 break-all'>
        //             {props.linkUrl?.includes("http") ? props.linkUrl : window.origin+props.linkUrl}
        //         </a>
        //     </Typography>
        // </Typography>
        // </Box>
        <Grid container spacing={1} className='m-t-5 m-b-5 display-flex align-center'>
        <Grid item className='p-t-0'>
            <Typography className='f-14 font-weight-400'>
            <a
                className='f-16 font-weight-600 text-capitalize m-l-5 chatbot-link'
                // onClick={(e)=>window.open(
                //     props.linkUrl?.includes("http")
                //     ? props.linkUrl
                //     : (props.linkUrl?.includes("candidate/find-jobs") && !localStorage.getItem("token"))
                //     ? window.origin + "/job-list"
                //     : window.origin + props.linkUrl,
                //     "_blank")
                // }
                href={
                    props.linkUrl?.includes("http")
                    ? props.linkUrl
                    : (props.linkUrl?.includes("candidate/find-jobs") && !localStorage.getItem("token"))
                    ? window.origin + `/job-list${props.linkUrl.split("?")?.length > 1 ? "?"+props.linkUrl.split("?")[1] : ""}`
                    : window.origin + ((props.linkUrl === "/forgotpassword") ? "/signin" : props.linkUrl) + getSearchParamKeyword()
                }
                target='_blank'
                rel="noreferrer"
            >
                {getCTAButtonTitle(props.linkUrl)}
            </a>
            </Typography>
        </Grid>
        </Grid>
    );
};
export default LinkWidget;

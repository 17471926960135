import { React, useRef, useState } from 'react';
import { Box, Typography, InputAdornment, IconButton, Input } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { handleSpaces } from '../../helpers/helper-data';
import ApiCalls from '../../api/ChatBotAxios';
import { useNavigate } from 'react-router';

const PasswordWidget = (props) => {
    const navigate = useNavigate();
    const [passwordValidationError, setPasswordValidationError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const passwordRef = useRef();
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
        setTimeout(()=>{
            passwordRef.current.selectionStart = passwordRef.current.value.length;
            passwordRef.current.selectionEnd = passwordRef.current.value.length;
        }, 10);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const signIn = async () => {
        let password = passwordRef?.current?.value;
        if (!password) {
            setPasswordValidationError("Please enter your password");
            return;
        } else if (password?.length < 8) {
            setPasswordValidationError("The password must be at least 8 characters");
            return;
        }
        const Url =
          process.env.REACT_APP_API_URL.replace("api/v1/", "chatbot/api/v1/") +
          "signin";
        const toSend = { username: props.email, password: password };
        await ApiCalls.put(Url, toSend)
          .then((res) => {
            if (res) {
              sessionStorage.clear();
              sessionStorage.setItem("idToken", res.idToken);
              sessionStorage.setItem("accessToken", res.accessToken);
            }
          })
          .catch(function (error) {
            if (error) {
              if (error == "User is not confirmed.") {
                // need to handle otp
              } else if (error == "Incorrect username or password.") {
                setPasswordValidationError("Incorrect password.");
              } else {
                setPasswordValidationError(error);
              }
            }
        });
    };
    return (
        <Box className='chatbot-password-box'>
            <Typography className='f-14 font-weight-600 gray9'>
                Password
            </Typography>
            <Input
                type={showPassword ? "text" : "password"}
                margin="normal"
                placeholder="Type here..."
                className="form-control input-group m-b-0 w-100 position-issue interview-questions p-r-0 send-questions"
                inputRef={passwordRef}
                onKeyPress={handleSpaces}
                onChange={(e)=>{
                    if (passwordValidationError != null) {
                        if (!e.target.value) {
                            setPasswordValidationError("Please enter your password");
                        } else if (e.target.value.length < 8) {
                            setPasswordValidationError("The password must be at least 8 characters");
                        } else {
                            setPasswordValidationError("");
                        }
                    }
                }}
                endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-label="hide view"
                        onKeyPress={handleClickShowPassword}
                        sx={{
                          "& .MuiTouchRipple-root": { position: "static" },
                        }}
                        tabIndex={0}
                      >
                        {showPassword ? (
                          <Visibility aria-label="Visibility icon" />
                        ) : (
                          <VisibilityOffIcon aria-label="Visibilityoff icon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
            />
            {passwordValidationError &&
                <Typography className="danger-color error-msg">
                    {passwordValidationError}
                </Typography>
            }
            <Typography className='f-14 font-weight-600 display-flex justify-end m-t-5'>
            <span className='link-color' onClick={signIn}>
                Submit
            </span>
            </Typography>
        </Box>
    );
};
export default PasswordWidget;

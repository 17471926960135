import axios from "axios";
import { authHeader } from "../../helpers/auth-header";
import ApiCalls from "../../api/customAxios";
class ProfileServiceClass {
  getProfileUrl = process.env.REACT_APP_API_URL + "candidates/";
  workhistoryUrl = process.env.REACT_APP_API_URL + "candidates/work-history";
  educationUrl = process.env.REACT_APP_API_URL + "candidates/educations";
  uploadFileUrl = process.env.REACT_APP_API_URL + "upload-file";
  uploadResumeUrl = process.env.REACT_APP_API_URL + "candidates/resume";
  publicSkillsUrl = `${process.env.REACT_APP_API_URL.replace(
    "api/v1/",
    "public/api/v1/"
  )}skills?keyword=`;
  candidateSkillsUrl = process.env.REACT_APP_API_URL + "candidates/skills";
  addDeviceTokenUrl =
    process.env.REACT_APP_API_URL + "candidates/notification/device";
  getCandidateReviewUrl =
    process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
    "enterprises/candidate-review/";
  candidateRemarkUrl =
    process.env.REACT_APP_API_URL + "candidates/pool/candidate-remark";
  locations = process.env.REACT_APP_API_URL + "places/";

  getProfile() {
    return ApiCalls.get(this.getProfileUrl);
  }

  updateProfile(payload) {
    return axios.put(this.getProfileUrl, payload, { headers: authHeader() });
  }

  // work histoy
  getAllWorkhistories() {
    return axios.get(this.workhistoryUrl, { headers: authHeader() });
  }
  addWorkhistory(payload) {
    return axios.put(this.workhistoryUrl, payload, { headers: authHeader() });
  }
  deleteWorkhistory(id) {
    return axios.delete(this.workhistoryUrl + "/" + id, {
      headers: authHeader(),
    });
  }
  deleteWorkExperienceDocument(id) {
    return axios.delete(this.workhistoryUrl + "/showcases/" + id, {
      headers: authHeader(),
    });
  }
  // education
  getAllEducations() {
    return axios.get(this.educationUrl, { headers: authHeader() });
  }
  addEducation(payload) {
    return axios.put(this.educationUrl, payload, { headers: authHeader() });
  }
  deleteEducation(id) {
    return axios.delete(this.educationUrl + "/" + id, {
      headers: authHeader(),
    });
  }

  deleteEducationDocument(id) {
    return axios.delete(this.educationUrl + "/documents/" + id, {
      headers: authHeader(),
    });
  }

  uploadFile(payload) {
    return axios.post(this.uploadFileUrl, payload, { headers: authHeader() });
  }

  getAllResume() {
    return axios.get(this.uploadResumeUrl, { headers: authHeader() });
  }
  uploadResume(payload) {
    return axios.post(this.uploadResumeUrl, payload, { headers: authHeader() });
  }

  deletResume(id) {
    return axios.delete(this.uploadResumeUrl + "/" + id, {
      headers: authHeader(),
    });
  }

  // skills
  getSkills(keyword) {
    // this is public base skills
    return axios.get(this.publicSkillsUrl + keyword);
  }

  // get candidate skills
  getCandidateSkills() {
    return axios.get(this.candidateSkillsUrl, { headers: authHeader() });
  }

  updateSkills(payload) {
    return axios.put(this.candidateSkillsUrl, payload, {
      headers: authHeader(),
    });
  }

  addDeviceToken(payload) {
    return axios.put(this.addDeviceTokenUrl, payload, {
      headers: authHeader(),
    });
  }

  deleteSkill(id) {
    return axios.delete(this.candidateSkillsUrl + "/" + id, {
      headers: authHeader(),
    });
  }

  // acceptTermOfservices

  acceptTermOfservices() {
    return axios.put(this.getProfileUrl + "/consented/versions", "", {
      headers: authHeader(),
    });
  }

  async getCandidateReview(id) {
    return ApiCalls.get(this.getCandidateReviewUrl + id);
  }

  async putCandidateRemark(jobId, remark) {
    return ApiCalls.put(this.candidateRemarkUrl, {
      jobId: jobId,
      ratingRemarksByCandidate: remark,
    });
  }
  async getLocations(query) {
    return ApiCalls.get(this.locations + query);
  }
}

const ProfileService = new ProfileServiceClass();
export default ProfileService;

import { React } from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import JobLocation from "../../assets/images/job-location-icon.svg";
import GoogleJob from "../../assets/images/google-job-icon.svg";
import FullTime from "../../assets/images/full-time-job.svg";
import { getFormattedWorkType, getLocation } from '../../helpers/helper-data';
import { useNavigate } from 'react-router-dom';

const JobcardWidget = (props) => {
    let navigate = useNavigate();
    return (
        <Box>
        <Box className='chatbot-jobcard-box m-t-5 m-b-5'>
            {props.jobs?.map((job, index) => {
              let jobDetails = {};
              try {
                jobDetails = JSON.parse(job.button_caption);
              } catch (err) {}
              return (index < 4 &&
                <Grid className={`${index < props.jobs?.length - 1 ? "borderBottom" : ""} py-20 pr-15 pl-15 ${index === 0 ? props.jobs?.length === 1 ? "chatbot-card-hover-color-with-border-radius" : "chatbot-card-hover-color-with-top-border-radius" : index === props.jobs?.length - 1 ? "chatbot-card-hover-color-with-bottom-border-radius" : "chatbot-card-hover-color"}`} key={index}>
                  <Typography
                    className="cursor-hover recommended-job-card"
                    onClick={()=>{
                      if (localStorage.getItem("token")) {
                        window.open(`/candidate/job-details/${jobDetails.id}`);
                      } else {
                        window.open(`/job/${jobDetails.id}`);
                      }
                    }}
                  >
                    <Typography className="display-flex justify-between file-upload">
                      <Typography className="">
                        <Typography className="display-flex">
                          <Typography className="f-14 font-weight-600 gray9">
                              {`${index + 1}.`}
                          </Typography>
                          <Typography className="pl-10 align-left">
                            <Typography
                              className="display-flex contact-setting-align ipad-mini-align break-space"
                            >
                              <Box className={`gray9 f-14 font-weight-600 text-capitalize ${props.isChatExpanded ? "w-300" : "w-200"} textEllipse m-r-5`}>
                                {jobDetails.jobTitle}
                              </Box>
                            </Typography>
                            {(jobDetails?.country || jobDetails?.city || jobDetails?.state) && (
                              <Typography className="display-flex align-center m-t-5 file-upload">
                                <Box className="display-flex flex-start m-t-2">
                                  <img
                                    width="15px"
                                    src={JobLocation}
                                    alt="img"
                                    className='m-t-2'
                                  />
                                  <Box className="supporting-text-dark m-l-5 mr-20">
                                    {getLocation(jobDetails)}
                                  </Box>
                                </Box>
                              </Typography>
                            )}
                            <Typography className="w-100 display-flex justify-between flex-start m-t-5 file-upload">
                              <Box className="display-flex align-center file-upload">
                                <Typography className="display-flex flex-start m-t-2">
                                  <img width="14px" src={GoogleJob} alt="img" className='m-t-2' />
                                  <Box className="supporting-text-dark m-l-5">
                                    {jobDetails.establishmentName
                                      ? jobDetails.establishmentName
                                      : "-"}
                                  </Box>
                                </Typography>
                              </Box>
                            </Typography>
                            <Typography className="w-100 display-flex justify-between flex-start m-t-5 file-upload">
                              <Box className="display-flex flex-start m-t-2">
                                <img width="14px" src={FullTime} alt="img" className='m-t-2' />
                                <Box className="supporting-text-dark m-l-5 display-flex align-center">
                                  {(() => {
                                    if (jobDetails?.jobType && jobDetails?.jobType.length > 0) {
                                      return jobDetails?.jobType.map((js, index) => {
                                        return index < 1 ? (
                                          <Box key={index}>
                                            <span className="supporting-text-dark m-r-5">
                                              {getFormattedWorkType(false, js)}
                                            </span>
                                          </Box>
                                        ) : null;
                                      });
                                    } else {
                                      return "-";
                                    }
                                  })()}
                                  {jobDetails?.jobType?.length > 1 && (
                                    <span
                                      className="cursor-hover text-decoration-none d-inline-flex f-12 font-weight-400"
                                    >
                                      {`+${
                                        jobDetails?.jobType && jobDetails?.jobType?.length - 1
                                      } more`}
                                    </span>
                                  )}
                                </Box>
                              </Box>
                            </Typography>
                          </Typography>
                        </Typography>
                      </Typography>
                      <Typography>
                      <Box className="ml-auto">
                            <Button
                              className="btn black-primary-button"
                              onClick={(e)=>{
                                e.stopPropagation();
                                if (localStorage.getItem("token")) {
                                  window.open(`/candidate/job-details/${jobDetails.id}`);
                                } else {
                                  window.open(`/job/${jobDetails.id}`);
                                }
                              }}
                            >
                              Apply
                            </Button>
                      </Box>
                      </Typography>
                    </Typography>
                  </Typography>
                </Grid>
              );
            })}
            {props.jobs?.length > 4 && (
              <Grid>
                <Typography
                  className="gray11 cursor-hover pr-15 pl-15 py-15 f-14 font-weight-500 text-center"
                  onClick={()=>{
                    if (localStorage.getItem("token")) {
                      window.open("/candidate/find-jobs");
                    } else {
                      window.open("/job-list");
                    }
                  }}
                >
                  View All Jobs
                </Typography>
              </Grid>
            )}
        </Box>
        </Box>
    );
};
export default JobcardWidget;

import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

function ConfirmDialog({
  open,
  setOpen,
  dialogTitle,
  description,
  handleConfirmYes,
  handleCloseForPitchVideo,
  isForCustomClose,
}) {
  const location = useLocation();
  let navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (location.pathname === "/candidate/create-profile") {
      if (reason && reason === "backdropClick") {
        return;
      }
      setOpen(false);
      navigate("/candidate/create-profile");
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={isForCustomClose ? handleCloseForPitchVideo : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="heading-text-color">
        {dialogTitle}
      </DialogTitle>
      <DialogContent className="pl-20">
        <DialogContentText
          id="alert-dialog-description"
          className="f-14 font-weight-400"
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="pb-20">
        <Button
          className="confirm-link dialog-secondary font-weight-600"
          onClick={isForCustomClose ? handleCloseForPitchVideo : handleClose}
          disableRipple="true"
          onKeyPress={isForCustomClose ? handleCloseForPitchVideo : handleClose}
          tabIndex={0}
        >
          No
        </Button>
        <Button
          className="confirm-link dialog-primary"
          onClick={handleConfirmYes}
          autoFocus={isForCustomClose ? false : true}
          disableRipple="true"
          onKeyPress={handleConfirmYes}
          tabIndex={0}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: false,
};

export const pageNotFoundSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    getPageNotFoundRecode: (state, action) => {
          state.error = action.payload;
      },
}
});

// Action creators are generated for each case reducer function
export const { getPageNotFoundRecode } = pageNotFoundSlice.actions;

export default pageNotFoundSlice.reducer;

import React, { useState, useEffect } from "react";
import { Typography, Grid, Container, Link, Box, Card, CardActionArea, CardMedia, CardContent } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import UsabilityFeature from "../assets/images/launch-page-images/usability-feature.png";
import FeatureImage from "../assets/images/launch-page-images/feature-image.png";
import FeatureImage1 from "../assets/images/launch-page-images/feature-image1.png";
import StackHolder1 from "../assets/images/launch-page-images/stackholder1.png";
import StackHolder2 from "../assets/images/launch-page-images/stackholder2.png";
import StackHolder3 from "../assets/images/launch-page-images/stackholder3.png";
import DesignAnalytics from "../assets/images/launch-page-images/design-analytics.png";
import DesignAnalytics2 from "../assets/images/launch-page-images/design-analytics2.png";
import DesignAnalytics3 from "../assets/images/launch-page-images/enterprise-candidateProfileview.png";
import DesignAnalytics4 from "../assets/images/launch-page-images/candidate-findJobs.png";
import Dashboard1 from "../assets/images/launch-page-images/dashboard1.png";
import Dashboard2 from "../assets/images/launch-page-images/dash-board-image.png";
import JobDetail from "../assets/images/launch-page-images/enterprise-JobPreview.png";
import CandidateDashboard from "../assets/images/launch-page-images/candidate-dashboard.png";
import JobImage from "../assets/images/launch-page-images/jobs-image.png";
import SkillIcon from "../assets/images/launch-page-images/skills-icon.png";
import InterestLevel from "../assets/images/launch-page-images/interest-level.png";
import WorkExperience from "../assets/images/launch-page-images/work-image.png";
import CheckList from "../assets/images/launch-page-images/checklist-image.png";
import KeyImage from "../assets/images/launch-page-images/key-image.png";
import CheckList1 from "../assets/images/launch-page-images/checklist-image1.png";
import EasyNavigation from "../assets/images/launch-page-images/easy-navigation.png";
import EmailImage from "../assets/images/launch-page-images/email.png";
import * as Constants from "../helpers/constants";
export default function CandidateFeature() {
    let navigate = useNavigate();

    useEffect(()=>{
        window.scrollTo( 0, 0 );
      }, []);

    return (
        <>
            <Container>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15 responsive-tabs">

                        <Grid data-aos="fade-left"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={6} md={12} xs={12} className="px-25">
                            <Grid className="py-10">
                                <Typography className="f-40 font-weight-800 landing-sub-text-color text-center">Features</Typography>
                            </Grid>

                            <Grid item lg={12} md={12} xs={12} className="py-10">
                                <Typography className="f-18 landing-sub-text-color text-center">Explore the features of {Constants.APP_NAME}, the ready-to-deploy talent platform designed to find great candidates faster</Typography>
                            </Grid>

                            <Grid item lg={12} md={12} xs={12} data-aos="zoom-in-left" className="align-item-center py-15" tabIndex={0}>
                                <a href="#tellmemoreaboutfeatures" className="btn learn-how-button text-center text-decoration-none" tabIndex={0}>Learn more</a>
                            </Grid>
                        </Grid>

                        <Grid item lg={6} md={12} xs={12} className="">
                            <img src={FeatureImage} alt="image" width="100%" />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80" id="tellmemoreaboutfeatures">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15 responsive-tabs">
                        <Grid item lg={12} md={12} xs={12}>
                            <Grid item md={12} xs={12} className="py-10">
                                <Typography className="f-18 landing-sub-text-color text-center">
                                    {Constants.APP_NAME} meets the needs of businesses wanting to <strong>source top talent</strong> <strong>directly</strong> without the hassle of hiring contractors through agencies.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center" >
                    <Grid  data-aos="fade-left"
                           data-aos-anchor="#example-anchor"
                           data-aos-offset="500"
                           data-aos-duration="500"
                           item lg={10}  md={12} xs={12} className="align-item-center responsive-tabs">
                        <Grid item lg={12} md={12} xs={12} className="">
                            <Grid item md={12} xs={12} className="py-10 text-center">
                                <Typography className="f-20 font-weight-800 landing-sub-text-color text-center">
                                    Our ready-to-deploy SaaS platform serves three major stakeholders:
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15" >
                    <Grid  data-aos="fade-left"
                           data-aos-anchor="#example-anchor"
                           data-aos-offset="500"
                           data-aos-duration="500"
                           item lg={10} md={12} xs={12} className="align-item-center  py-15" >
                        <Grid item lg={12} md={12} xs={12} className="display-flex responsive-tabs">
                            <Grid data-aos="zoom-in-left" item lg={4} md={12} xs={12} className="px-25 py-15 section-hover-zoom">
                                <Card className="launch-page-card" sx={{ maxWidth: 345 }} style={{ backgroundColor: '#e8f5fe' }}>
                                    <CardActionArea>
                                        <CardMedia className=""
                                                   component="img"
                                                   height="300"
                                                   src={StackHolder1}
                                                   alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom  className="f-20 text-center text-decor-color">
                                                Candidates
                                            </Typography>
                                            <Typography variant="body2" className="landing-sub-text-color text-center f-16">
                                                Learn more…
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>

                            <Grid data-aos="zoom-in-left" item lg={4} md={12} xs={12} className="px-25 py-15 section-hover-zoom">
                                <Card className="launch-page-card" sx={{ maxWidth: 345 }} style={{ backgroundColor: '#e8f5fe' }}>
                                    <CardActionArea>
                                        <CardMedia className=""
                                                   component="img"
                                                   height="300"
                                                   src={StackHolder2}
                                                   alt="green iguana"
                                                   style={{ objectFit: 'contain' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom  className="f-20 text-center text-decor-color">
                                                Hiring Managers
                                            </Typography>
                                            <Typography variant="body2" className="landing-sub-text-color text-center f-16">
                                                Learn more…
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>

                            <Grid data-aos="zoom-in-left" item lg={4} md={12} xs={12} className="px-25 py-15 section-hover-zoom">
                                <Card className="launch-page-card" sx={{ maxWidth: 345 }} style={{ backgroundColor: '#e8f5fe' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            className=""
                                            component="img"
                                            src={StackHolder3}
                                            alt="green iguana"
                                            height="300"
                                            style={{ objectFit: 'contain' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom  className="f-20 text-center text-decor-color">
                                                Recruiters
                                            </Typography>
                                            <Typography variant="body2" className="landing-sub-text-color text-center f-16">
                                                Learn more…
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15 responsive-tabs">

                        <Grid data-aos="fade-left"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={6}  md={12} xs={12} className="px-25">
                            <Grid item md={12} xs={12} className="">
                                <Typography className="f-30 font-weight-800 landing-sub-text-color text-center">Source the talent you need by the</Typography>
                            </Grid>

                            <Grid item md={12} xs={12} className="">
                                <Typography className="f-30 font-weight-800 warn-color text-center"> hour, project or role.</Typography>
                            </Grid>

                            <Grid item md={12} xs={12} className="py-10">
                                <Typography className="f-18 landing-sub-text-color text-center">
                                    <p><strong>The audience of people moving to a self-employed gig working lifestyle is growing rapidly.&nbsp;</strong>
                                        &nbsp;That’s a big and hugely skilled talent pool waiting for your business to leverage it.&nbsp;&nbsp;
                                        <span>Doing so, will allow your business to get work done faster.</span></p>
                                </Typography>
                            </Grid>

                            <Grid item md={12} xs={12} className="py-10">
                                <Typography className="f-18 landing-sub-text-color text-center">
                                    Businesses today look to adopt a<strong> total talent</strong> approach that combines full-time, contingent freelancers and pay-as-you-go gig workers.&nbsp; Tap into the top contingent talent on-demand, without the hassle of dealing with agencies or hiring contractors (unless you prefer).
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item lg={6} md={12} xs={12} className="">
                            <img src={FeatureImage1} alt="image" width="100%" />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15">

                        <Grid data-aos="fade-left"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25 display-flex responsive-tabs">
                            <Grid data-aos="zoom-in-left" item lg={4} md={12} xs={12} className="px-10">
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-30 font-weight-400 landing-sub-text-color text-center"> Candidate Features</Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-20  text-center"><a className="text-decoration-none" style={{ color: '#11bafa' }} href="#jobviews">Rich job views that aid matching</a><span>&nbsp;</span></Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-20 font-weight-800  text-center"><a className="text-decoration-none" style={{ color: '#11bafa' }} href="#candidateprofile">Insightful candidate profile</a><span>&nbsp;</span></Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-20 font-weight-800  text-center" ><a className="text-decoration-none"  style={{ color: '#11bafa' }} href="#joblistings">Intuitive job listings</a><span>&nbsp;</span></Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-20 font-weight-800  text-center" ><a className="text-decoration-none"  style={{ color: '#11bafa' }} href="#candidateDashboardSection">Candidate dashboard</a><span>&nbsp;</span></Typography>
                                </Grid>

                            </Grid>

                            <Grid data-aos="zoom-in-left" item lg={4} md={12} xs={12} className="px-10">
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-30 font-weight-400 landing-sub-text-color px-10"> Benefits</Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <ul className="px-25">
                                        <li className="py-10"><strong> Painless (one-click) sign-up </strong> integrating with existing platforms like LinkedIn, Facebook and Google</li>
                                        <li className="py-10"><strong>Rich profiling information </strong> that’s easy for applicants to complete.</li>
                                        <li className="py-10"><strong>Intuitive job search forms</strong> with powerful filtering that makes it easier for prospective candidates to find opportunities tailored to their capabilities and aspirations</li>
                                    </ul>
                                </Grid>
                            </Grid>

                            <Grid data-aos="zoom-in-left" item lg={4} md={12} xs={12} className="px-10" >
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-30 font-weight-400 landing-sub-text-color"> What’s different?</Typography>
                                </Grid>
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography>We leverage the best in digital talent systems with powerful (in-app) dashboard reporting, artificial intelligence, and chatbot enabling tools to maximize the user experience.</Typography>
                                </Grid>
                                <Grid item md={12} xs={12} className="py-10">
                                    <p>We’ve fine-tuned every workflow step, form, and data entry task to balance usefulness with usability.</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15 responsive-tabs">

                        <Grid data-aos="fade-left" id="jobviews"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25">
                            <Grid item md={12} xs={12} className="py-10">
                                <Typography className="f-30 font-weight-400 landing-sub-text-color text-center">
                                    Rich Job Views that Aid Matching
                                </Typography>
                            </Grid>

                            <Grid item md={12} xs={12} className="py-10">
                                <Typography className="f-18 font-weight-400 landing-sub-text-color text-center">We’ve designed our Job Views in conjunction with Candidates to make sure they’re presenting the information applicants care about.
                                </Typography>
                            </Grid>

                            <Grid item md={12} xs={12} className="py-10">
                                <Typography className="f-18 font-weight-400 landing-sub-text-color text-center">
                                    Alerts and simple one-click applications make it simpler for candidates to apply.
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-40">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15 responsive-tabs">

                        <Grid data-aos="fade-left"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25 display-flex responsive-tabs">
                            <Grid data-aos="zoom-in-left" item lg={4} md={12} xs={12} className="px-25">
                                <img src={JobDetail} alt="image" width="600px" />
                            </Grid>

                            <Grid  item lg={8} md={12} xs={12} className="px-25">
                                <Grid className="py-10">
                                    <Typography className="f-25 font-weight-400 landing-sub-text-color">
                                        <Typography className="display-flex">
                                            <span><img src={JobImage} alt="job" width="35px" height="35px"/></span>
                                            <span className="f-25 font-weight-400 landing-sub-text-color">Comprehensive job details</span>
                                        </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color">
                                        We make it easier for potential candidates to find out more about the sorts of work opportunities that interest them.
                                    </Typography>
                                </Grid>


                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-25 font-weight-400 landing-sub-text-color">
                                       <Typography className="display-flex">
                                           <span><img src={SkillIcon} alt="skillIcon" width="35px" height="35px"/></span>
                                           <span className="f-25 font-weight-400 landing-sub-text-color">Skill summaries</span>
                                       </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color">
                                        Skills summaries are easy for recruiters and hiring managers to compose, yet tell the full story on the competencies required to be successful in the role.
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-25 font-weight-400 landing-sub-text-color">
                                       <Typography className="display-flex">
                                           <span><img src={InterestLevel} alt="intersetlevel" width="35px" height="35px"/></span>
                                           <span className="f-25 font-weight-400 landing-sub-text-color">Signposting of interest levels</span>
                                       </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color">
                                        Candidates get a ‘heads-up’ on the level of interest in the role.  This results in a sense of urgency that leads to higher response levels.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80">
                    <Grid item lg={6} md={12} xs={12} className="align-item-center py-15 responsive-tabs">

                        <Grid data-aos="fade-left" id="candidateprofile"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25">
                            <Grid item md={12} xs={12} className="py-10">
                                <Typography className="f-30 font-weight-400 landing-sub-text-color text-center">Insightful Candidate Profile
                                </Typography>
                            </Grid>

                            <Grid  item md={12} xs={12} className="py-10">
                                <Typography className="f-18 font-weight-400 landing-sub-text-color text-center">
                                    Candidates can produce a professional candidate profile that delivers rich insights to hirers and recruiters with ease.
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-40">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15 responsive-tabs">

                        <Grid data-aos="fade-left"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25 display-flex responsive-tabs">

                            <Grid  item lg={8} md={12} xs={12} className="px-25">
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-25 font-weight-400 landing-sub-text-color">
                                       <Typography className="display-flex">
                                           <span><img src={WorkExperience} alt="workexperience" width="35px" height="35px"/></span>
                                           <span className="f-25 font-weight-400 landing-sub-text-color">Work experience</span>
                                       </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color">
                                        Applicants can complete a detailed synopsis of their work experience including links to company sites and projects, etc.
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-25 font-weight-400 landing-sub-text-color">
                                        <Typography className="display-flex">
                                            <span><img src={CheckList} width="35px" height="35px" alt="img" /></span>
                                            <span className="f-25 font-weight-400 landing-sub-text-color">Checklist</span>
                                        </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color">
                                        Our friendly checklist encourages candidates to complete their profile in full.  They can return to their profile at any time to update details or pick up from where they left off.
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-25 font-weight-400 landing-sub-text-color">
                                       <Typography className="display-flex">
                                           <span><img src={KeyImage} alt="image" width="35px" height="35px"/></span>
                                           <span className="f-25 font-weight-400 landing-sub-text-color">Privacy controls</span>
                                       </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color">
                                        Candidates control how much information they share and how it is distributed.
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid lg={4} item md={12} xs={12} data-aos="zoom-in-left" className="px-25">
                                <img src={DesignAnalytics3} alt="image" width="600" />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center demand-hiring p-25">
                    <Grid data-aos="fade-left"
                          data-aos-anchor="#example-anchor"
                          data-aos-offset="500"
                          data-aos-duration="500" item lg={12} md={12} xs={12} className="align-item-center hiring-talent py-15 responsive-tabs">

                        <Grid data-aos="zoom-in-left" item lg={12} md={12} xs={12} className="py-10 px-25">
                            <Grid item md={12} xs={12} className="inner-design-section">
                                <h1 className="c-white f-32 text-center">
                                    Try out the platform
                                </h1>
                            </Grid>

                            <Grid  data-aos="zoom-in-left" item lg={12} md={12} xs={12} className="align-item-center py-25">
                                <a href="https://themetablue.com/contact-us/" className="btn book-demo-button text-decoration-none" target="_blank" rel="noopener noreferrer">Book a demo</a>
                            </Grid>

                            <Grid item lg={12} md={12} xs={12} className="align-item-center section-hover-zoom">
                                <p className="c-white"><span><a href="https://themetablue.com/speak-to-a-product-specialist/" className="text-decoration-none c-white" target="_blank" rel="noopener noreferrer">Get in touch with a product specialist</a></span>
                                    <span className="p-10"><ArrowRightAltIcon style={{ verticalAlign: 'middle' }}/></span>
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15 responsive-tabs">

                        <Grid data-aos="fade-left" id="joblistings"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25 display-flex">

                            <Grid  item lg={12} md={12} xs={12} className="px-25">
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-30 font-weight-400 landing-sub-text-color text-center">
                                        Intuitive Job Listings
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color text-center">
                                        It’s easy for prospective candidates to browse through work opportunities most suited to their skills, interests, and availability.
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color text-center">
                                        As a general rule, the simpler your application process, the more talent you will attract.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15">

                        <Grid data-aos="fade-left"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25">
                            <Grid data-aos="zoom-in-left" item lg={12} md={12} xs={12} className="px-25">
                                <img src={DesignAnalytics4} alt="image" width="100%" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15 responsive-tabs">

                        <Grid data-aos="fade-left"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25 display-flex responsive-tabs">

                            <Grid  item lg={6} md={12} xs={12} className="px-25">
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-30 font-weight-400 landing-sub-text-color text-center">
                                       <Typography className="display-flex">
                                           <span><img src={CheckList1} alt="image" width="35px" height="35px"/></span>
                                           <span className="f-30 font-weight-400 landing-sub-text-color text-center"> Find relevant jobs easier</span>
                                       </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color text-center">
                                        <p>We’ve added a <strong>‘Remote location’</strong> check-box to profile relevant opportunities for remote workers, and an <strong>‘Easy Apply Only’</strong> check box too.&nbsp; This highlights those opportunities that offer a painless application process.&nbsp;</p>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid  item lg={6} md={12} xs={12} className="px-25">
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-30 font-weight-400 landing-sub-text-color text-center">
                                     <Typography className="display-flex">
                                         <span><img src={CheckList} alt="image" width="35px" height="35px"/></span>
                                         <span className="f-30 font-weight-400 landing-sub-text-color text-center">Bookmarking favorites</span>
                                     </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color text-center">
                                        Candidates can bookmark the long-list opportunities most interesting to them and draw down on the best of the best prior to applying, saving time for candidates and effort for recruiters.
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80" >
                    <Grid item lg={10} md={12} xs={12} className="align-item-center responsive-tabs py-15">

                        <Grid data-aos="fade-left"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25 display-flex"
                              id="candidateDashboardSection">

                            <Grid  item lg={12} md={12} xs={12} className="px-25">
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-30 font-weight-400 landing-sub-text-color text-center">
                                        A candidate dashboard that breathes life into social media and job search data
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color text-center">
                                        Candidates can access a rich dashboard that highlights the level of interest from recruiters in their profile, and helps to progress their applications.  A profile score feature encourages candidates to put their best foot forward and to complete their profile in full.
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15">
                    <Grid item lg={10} md={12} xs={12} className="align-item-center py-15 responsive-tabs">
                        <Grid data-aos="fade-left"
                              data-aos-anchor="#example-anchor"
                              data-aos-offset="500"
                              data-aos-duration="500" item lg={12} md={12} xs={12} className="px-25 display-flex responsive-tabs">

                            <Grid  item lg={6} md={12} xs={12} className="px-25">
                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-25 font-weight-400 landing-sub-text-color">
                                       <Typography className="display-flex">
                                           <span><img src={EasyNavigation} alt="image" width="35px" height="35px"/></span>
                                           <span className="f-25 font-weight-400 landing-sub-text-color"> Easy to navigate</span>
                                       </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color">
                                        Candidates won’t need a user manual to learn how to use our rich dashboard features. Its simple layout  brings all the insights candidates will need to their fingertips.
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-25 font-weight-400 landing-sub-text-color">
                                       <Typography className="display-flex">
                                           <span><img src={EmailImage} alt="img" width="35px" height="35px"/></span>
                                           <span className="f-25 font-weight-400 landing-sub-text-color">Real-time updates and notifications</span>
                                       </Typography>
                                    </Typography>
                                </Grid>

                                <Grid item md={12} xs={12} className="py-10">
                                    <Typography className="f-18 font-weight-400 landing-sub-text-color">
                                        Kept candidates up-to-date on the latest progress on applications which they can view on mobile or desktop.  Additionally, email notifications can be personalized to optimize the candidate experience.
                                    </Typography>
                                </Grid>

                            </Grid>

                            <Grid item md={12} xs={12} data-aos="zoom-in-left" lg={6} className="px-25">
                                <img src={CandidateDashboard} alt="image" width="100%" />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15 pt-80" >
                    <Grid  data-aos="fade-left"
                           data-aos-anchor="#example-anchor"
                           data-aos-offset="500"
                           data-aos-duration="500"
                           item lg={10} md={12} xs={12} className="align-item-center responsive-tabs py-15" >
                        <Grid item lg={12} md={12} xs={12} className="">
                            <Grid item md={12} xs={12} className="py-10 text-center">
                                <Typography className="f-40 font-weight-800 landing-sub-text-color text-center">
                                    Want to learn more?
                                </Typography>
                            </Grid>

                            <Grid item md={12} xs={12} className="py-10 text-center">
                                <Typography className="f-18  landing-sub-text-color text-center">
                                    Explore features for…
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item lg={12} md={12} xs={12} spacing={1} className="align-item-center py-15" >
                    <Grid  data-aos="fade-left"
                           data-aos-anchor="#example-anchor"
                           data-aos-offset="500"
                           data-aos-duration="500"
                           item lg={8} md={12} xs={12} className="align-item-center responsive-tabs py-15" >
                        <Grid item lg={12} md={12} xs={12} className="display-flex responsive-tabs">
                            <Grid data-aos="zoom-in-left" item lg={6} md={12} xs={12} className="px-25 py-15 section-hover-zoom">
                                <Card className="launch-page-card" sx={{ maxWidth: 345 }} style={{ backgroundColor: '#e8f5fe' }}>
                                    <CardActionArea>
                                        <CardMedia className=""
                                                   component="img"
                                                   height="300"
                                                   src={StackHolder2}
                                                   alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom  className="f-20 text-center text-decor-color">
                                                Hiring Managers
                                            </Typography>
                                            <Typography variant="body2" className="landing-sub-text-color text-center f-16">
                                                Learn more…
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>

                            <Grid data-aos="zoom-in-left" item lg={6} md={12} xs={12} className="px-25 py-15 section-hover-zoom">
                                <Card className="launch-page-card" sx={{ maxWidth: 345 }} style={{ backgroundColor: '#e8f5fe' }}>
                                    <CardActionArea>
                                        <CardMedia className=""
                                                   component="img"
                                                   height="300"
                                                   src={StackHolder3}
                                                   alt="green iguana"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom  className="f-20 text-center text-decor-color">
                                                Recruiters
                                            </Typography>
                                            <Typography variant="body2" className="landing-sub-text-color text-center f-16">
                                                Learn more…
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>

    );
}



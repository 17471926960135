import { React } from 'react';
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as ChatbotHowToUseIcon } from '../../assets/images/chatbot-how-to-use-icon.svg';
import { ReactComponent as ChatbotTipsIcon } from '../../assets/images/chatbot-tips-icon.svg';

const ChatbotInfo = (props) => {
    return (
        <Box>
            <Grid container lg={12} md={12} xs={12}>
                <Typography className='f-14 font-weight-400 gray9 ml-20 cursor-hover text-decor-underline'
                    onClick={()=>props.setShowChatInfo(false)}
                >
                    Go Back
                </Typography>
            </Grid>
            <Typography className='chatbot-feedback-box'>
                <Grid container lg={12} md={12} xs={12} className='display-flex align-center chatbot-info-card-border'>
                    <Grid item lg={12} md={12} xs={12} className='display-flex align-center justify-center'>
                        <ChatbotHowToUseIcon/>
                        <Typography className='f-20 font-weight-600 gray10 ml-10'>How To Use:</Typography>
                    </Grid>
                    <ul>
                        <li className='f-16 font-weight-400 gray8'>
                            Ask the AI assistant anything related to the hiring ecosystem.
                        </li>
                        <li className='f-16 font-weight-400 gray8'>
                            Utilize the microphone option for speech-to-text interaction.
                        </li>
                        <li className='f-16 font-weight-400 gray8'>
                            Explore platform functionalities by asking questions like "How can I apply for jobs?"
                        </li>
                    </ul>
                </Grid>
                <Grid container lg={12} md={12} xs={12} className='display-flex align-center mt-20 chatbot-info-card-border'>
                    <Grid item lg={12} md={12} xs={12} className='display-flex align-center justify-center'>
                        <ChatbotTipsIcon/>
                        <Typography className='f-20 font-weight-600 gray10 ml-10'>Tips:</Typography>
                    </Grid>
                    <ul>
                        <li className='f-16 font-weight-400 gray8'>
                            Avoid using short phrases; use complete sentences for better results.
                        </li>
                        <li className='f-16 font-weight-400 gray8'>
                            The AI assistant maintains chat history-based responses. To start fresh, restart your chat by closing it.
                        </li>
                        <li className='f-16 font-weight-400 gray8'>
                            Provide detailed information or context when asking questions to receive more accurate and relevant responses.
                        </li>
                        <li className='f-16 font-weight-400 gray8'>
                            When formulating your questions, try to include relevant keywords or terms to help the AI assistant understand your intent more effectively.
                        </li>
                        <li className='f-16 font-weight-400 gray8'>
                            If you encounter any issues or have suggestions for improvement, feel free to provide feedback to help enhance the AI assistant's performance.
                        </li>
                    </ul>
                </Grid>
            </Typography>
        </Box>
    );
};
export default ChatbotInfo;

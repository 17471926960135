// CookieConsent.js
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation } from 'react-router-dom';
import { ReactComponent as CookieIcon } from "./assets/images/cookie-policy-icon.svg";
import { checkForCookiePopUp } from './helpers/helper';
import { useModal } from './ModalContext';

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    if (!cookies.cookieConsent && checkForCookiePopUp(location)) {
      const urlParams = new URLSearchParams(window.location.search);      //poolId is from talent pool referral
      // if (!cookies.cookieConsent) {
      //     setCookie('cookieConsent', 'accepted', { path: '/', maxAge: 365 * 24 * 60 * 60 });
      //     // const currentUrl = new URL(window.location.href);
      //     // currentUrl.searchParams.set('cookieContent', 'true');
      //     // const modifiedUrl = currentUrl.toString();
      //     // history.replaceState(null, '', modifiedUrl);
      // } else {
        if (location.pathname == "/") {
          setTimeout(() => {
            setShow(true);
            openModal(true);
          }, 1200);
        } else {
        setShow(true);
        openModal(true);
      }
      // }
    }
  }, [location]);

  const handleAccept = () => {
    setCookie('cookieConsent', 'accepted', { path: '/', maxAge: 365 * 24 * 60 * 60 });
    setShow(false);
    closeModal();
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleOpenViewDetails = () => {
    window.open(`/cookiepolicy`);
  };

  if (!show) return null;

  return (
    <>
    <Modal
    className=""
    open={show}
    // onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    id="upload-logo-image-modal"
  >
    <Box className="modal-content inputcontrasts">
    <Grid container id="modal-title">
        <Grid item xs={8} className="display-flex align-center">
          <Box className="display-flex">
            <CookieIcon  width="25px" height="25px" />
          </Box>
          <Typography className="f-20 font-weight-600 zoom-font heading-text-color ml-10">
           Cookie Policy
          </Typography>
        </Grid>
      </Grid>

                  <Grid id="modal-description">
                      <Typography className="m-l-5 f-14 font-weight-400 sub-text-color  mt-5">
                      We use cookies and similar technologies for technical purposes and, with your consent, to enhance functionality and user experience as outlined in our cookie policy. Click "View Details" to read the full policy. Use the "Accept Essential Cookies" button to consent.
                      </Typography>
                  </Grid>
        <Grid id="modal-footer"  className="justify-end display-flex mt-30">
                    <Typography className={`display-flex align-center ${window.location.pathname === "/" ? "cookie-btn" : ""}`}>
                        {/* <Typography className="link-color f-14 font-weight-400 mr-20" onClick={handleReset}>Reset</Typography> */}
                        <Button
                            className="btn secondary-button mr-15 cookie-secondary-button"
                            // disabled={subject === "" || review === "" || subjectError}
                            onClick={handleOpenViewDetails}
                            tabIndex={0}
                            disableRipple="true"
                        >
                            View Details
                        </Button>
                        <Button
                            className="btn primary-button cookie-Primary-button"
                            // disabled={subject === "" || review === "" || subjectError}
                            onClick={handleAccept}
                            tabIndex={0}
                            disableRipple="true"
                        >
                            Accept Essential Cookies
                        </Button>
                    </Typography>
                    </Grid>
    </Box>
  </Modal>
  </>
  );
};

export default CookieConsent;

import React, { useState, useEffect } from "react";
import { Badge, Grid, Typography, Container, Tooltip, Button, MenuItem, Box } from "@mui/material";
import moment from "moment";
import ApiCalls from "../../../api/customAxios";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import {
    formatDate,
    isMinutesOrHours,
} from "../../../helpers/helper-data";
import { ReactComponent as NotificationDefaultIcon } from "../../../assets/images/notification-default-icon.svg";
import { ReactComponent as NoNotificationIcon } from "../../../assets/images/NoJobsFound.svg";
import { danger } from "../../../Components/shared/messageBox";
import notificatioIcon from "../../../assets/images/notification-icon-new.svg";
import NotificationList from "./NotificationList";
import { useSelector } from "react-redux";

export default function NotificationButton(props) {
    const appOrigin = useSelector((state) => state.location.origin);
    let navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const [anchorE3, setAnchorE3] = useState(null);
    const open3 = Boolean(anchorE3);
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [notificationListDrawer, setNotificationListDrawer] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allnotifications, setallnotifications] = useState([]);
    const [selectedbutton, setselectedbutton] = useState("All");

    const handleClick3 = (event) => {
        if (!anchorE3) {
            setAnchorE3(event.currentTarget);
        } else {
            setAnchorE3(null);
            setselectedbutton("All");
            allnotificationslist();
        }
    };
    const handleClose3 = () => {
        setAnchorE3(null);
        setselectedbutton("All");
        allnotificationslist();
    };
    useEffect(() => {
        ApiCalls.get("candidates/notifications/unread/C")
        .then((response) => {
                setNotificationsCount(response.unReadNotifications);
        })
        .catch(function (error) { });
        getNotificationsDtoList();
        if (queryParams.get("keyword") === "notifications") {
            setTimeout(()=>{
                document.getElementById("basic-button3")?.click();
                const baseUrl = window.location.origin + window.location.pathname;
                window.history.replaceState({}, document.title, baseUrl);
            }, 2000);
        }
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            if (selectedbutton == "All") {
                getNotificationsDtoList();
              } else {
                getUnreadNotificationsDtoList();
              }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [selectedbutton]);

    const allnotificationslist = () => {
        // setNotifications(allnotifications);
        setselectedbutton("All");
        getNotificationsDtoList();
        console.log(allnotifications);
      };
    const getNotificationsDtoList = () => {
        let mounted = true;
        ApiCalls.get("candidates/notifications/C/0/10")
            .then((response) => {
                if (mounted) {
                    setNotifications(response.notificationsDtoList);
                    setallnotifications(response.notificationsDtoList);
                    setNotificationsCount(response.unReadNotifications);
                }
            })
            .catch(function (error) { });
        return () => (mounted = false);
    };
    const getUnreadNotificationsDtoList = () => {
        console.log("Inside getUnreadNotificationsDtoList");
        let mounted = true;
        ApiCalls.get("candidates/notifications/unread/C")
            .then((response) => {
                if (mounted) {
                  console.log(response);
                    setNotifications(response.notificationsDtoList);
                    setNotificationsCount(response.unReadNotifications);
                    setselectedbutton("Unread");
                }
            })
            .catch(function (error) { });
        return () => (mounted = false);
    };
    const getUpdatedUnreadCount = () => {
        console.log("2-> Inside getUpdatedUnreadCount");
        ApiCalls.get("candidates/notifications/unread/C")
          .then((response) => {
            console.log("3->After API call");
            console.log(response.unReadNotifications);
            setNotificationsCount(response.unReadNotifications);
          })
          .catch(function (error) { });
      };
    const handleNotificationRead = () => {
        // ApiCalls.put("candidates/notifications/C")
        //   .then((response) => {
        //     setNotificationsCount(0);
        //   })
        //   .catch(function (error) {});
    };
    const handleJobsApiCall = async(status, jobId) => {
        try {
          const response = await ApiCalls.get("candidates/pool/" + status + `?jobId=${jobId}`);
          return response;
        } catch (error) {
          return error?.errorMessage;
        }
    };
    function extractDateFromString(startDate, endDate) {
        const currentDate = moment().format("YYYY-MM-DD");
        const slotExpiry = moment(startDate).local().format("YYYY-MM-DD");
        let diffInMinutes = moment(endDate).local().diff(moment(), "minutes");
        let diffInDays = slotExpiry ? moment(currentDate).diff(moment(slotExpiry), 'days') : 0;
        return diffInDays <= 0 && diffInMinutes < 0;
    };
    const interviewDetails = (url, notification) => {
        let arr = url.split("/");
        let Url = `${process.env.REACT_APP_API_URL}candidates/interviews/schedules/${arr[arr?.length - 2]}`;
        ApiCalls.get(Url)
          .then((response) => {
            if (response?.interviewSchedulesDto?.length) {
               let scheduleRound = response?.interviewSchedulesDto[arr[arr?.length - 1] - 1];
               if (((scheduleRound?.ratingOfPanelByCandidate == null && response?.closureStatus != null) || scheduleRound?.ratingOfPanelByCandidate != null) && notification.notificationValue?.includes("interview feedback")) {
                danger("The notification you're trying to access has expired.");
                return;
              } else if (scheduleRound?.status != "P" && notification.notificationValue?.includes("received an interview invite")) {
                danger("The notification you're trying to access has expired.");
                return;
              } else if (scheduleRound?.status != "P" && notification.notificationValue?.includes("rescheduled")) {
                danger("The notification you're trying to access has expired.");
                return;
              } else if (scheduleRound?.status != "X" && notification.notificationValue?.includes("cancelled the interview")) {
                danger("The notification you're trying to access has expired.");
                return;
              } else if (scheduleRound?.status == "A" && notification.notificationValue?.includes("upcoming interview") && extractDateFromString(scheduleRound?.scheduleStartDateTime, scheduleRound?.scheduleCompleteDateTime)) {
                danger("The notification you're trying to access has expired.");
                return;
              } else if (((scheduleRound?.status == "A" && scheduleRound?.roundCompleted == "Y") || scheduleRound?.status != "A") && notification.notificationValue?.includes("upcoming interview")) {
                danger("The notification you're trying to access has expired.");
                return;
              } else if (scheduleRound?.ratingOfPanelByCandidate == null && notification.notificationValue?.includes("interview feedback") && response?.closureStatus == null) {
                navigate(url, { state: { isScorecard: true } });
              } else {
                navigate(url);
              }
            };
          })
          .catch((err) => {
            if (err?.statusCode!= 500) {
                danger("Something went wrong");
            }
          });
      };
    const handleAllNotificationRead = () => {
        ApiCalls.put("candidates/notifications/C")
            .then((response) => {
                getNotificationsDtoList();
                // setNotificationsCount(0);
                setNotificationsCount(response.unReadNotifications);
                handleClose3();
            })
            .catch(function (error) { });
    };
    const viewAllNotification = () => {
        setNotificationListDrawer(true);
        handleClose3();
    };
    var onedayBefore = new Date();
    onedayBefore.setDate(onedayBefore.getDate() - 1);
    var currentDateWeekFormat = new Date();
    currentDateWeekFormat.setDate(currentDateWeekFormat.getDate() - 7);
    const handleNotificationNavigation = async (notification) => {
        setIsLoading(true);
        ApiCalls.put(`candidates/notifications/${notification.id}/R`)
            .then((response) => {
                // setNotificationsCount(0);
                getUpdatedUnreadCount();
            })
            .catch(function (error) { });
        const searchString = "/candidate";
        if (notification.callToActionUrl.includes("jobId")) {
            if (notification.callToActionUrl.includes("Offered")) {
                const paramDelimiter = "&";
                const startIndex = notification.callToActionUrl.indexOf(searchString);
                const endIndex = notification.callToActionUrl.indexOf(paramDelimiter, startIndex);
                const match = notification.callToActionUrl.match(/&jobId=([^&]+)/);
                console.log(match);
                const extractedString = notification.callToActionUrl.substring(startIndex, endIndex);
                const statusResponse = await handleJobsApiCall("F", match[1]);
                if ((statusResponse[0]?.candidateProcessStatus == null || statusResponse[0]?.candidateProcessStatus == "A" || statusResponse[0]?.candidateProcessStatus == "P") && statusResponse[0]?.enterpriseProcessStatus == "F") {
                    const inputString = match[0];
                    let jobIde = inputString.replace("&jobId=", "");
                    let payLoadState = {
                        jobId: jobIde,
                        tabValue: match[1]
                    };
                    navigate(extractedString, { state: payLoadState });
                    handleClose3();
                    setIsLoading(false);
                    getUpdatedUnreadCount();
                } else {
                    danger("The notification you're trying to access has expired.");
                    handleClose3();
                    setIsLoading(false);
                    getUpdatedUnreadCount();
                    return;
                }
            } else if (notification.callToActionUrl.includes("Onboarded")) {
                const paramDelimiter = "&";
                const startIndex = notification.callToActionUrl.indexOf(searchString);
                const endIndex = notification.callToActionUrl.indexOf(paramDelimiter, startIndex);
                const match = notification.callToActionUrl.match(/&jobId=([^&]+)/);
                const extractedString = notification.callToActionUrl.substring(startIndex, endIndex);
                const statusResponse = await handleJobsApiCall("O", match[1]);
                if (statusResponse[0]?.candidateProcessStatus == "P" && statusResponse[0]?.enterpriseProcessStatus == "O") {
                    const inputString = match[0];
                    let jobIde = inputString.replace("&jobId=", "");
                    let payLoadState = {
                        jobId: jobIde,
                        tabValue: match[1]
                    };
                    navigate(extractedString, { state: payLoadState });
                    handleClose3();
                    setIsLoading(false);
                    getUpdatedUnreadCount();
                } else {
                    danger("The notification you're trying to access has expired.");
                    handleClose3();
                    setIsLoading(false);
                    getUpdatedUnreadCount();
                    return;
                }
            } else if (notification.callToActionUrl.includes("Offboarded")) {
                const paramDelimiter = "&";
                const startIndex = notification.callToActionUrl.indexOf(searchString);
                const endIndex = notification.callToActionUrl.indexOf(paramDelimiter, startIndex);
                const match = notification.callToActionUrl.match(/&jobId=([^&]+)/);
                const extractedString = notification.callToActionUrl.substring(startIndex, endIndex);
                const statusResponse = await handleJobsApiCall("O", match[1]);
                if (statusResponse[0]?.candidateProcessStatus == null && statusResponse[0]?.enterpriseProcessStatus == "B") {
                    const inputString = match[0];
                    let jobIde = inputString.replace("&jobId=", "");
                    let payLoadState = {
                        jobId: jobIde,
                        tabValue: match[1]
                    };
                    navigate(extractedString, { state: payLoadState });
                    handleClose3();
                    setIsLoading(false);
                    getUpdatedUnreadCount();
                } else {
                    danger("The notification you're trying to access has expired.");
                    handleClose3();
                    setIsLoading(false);
                    getUpdatedUnreadCount();
                    return;
                }
            } else if (notification.callToActionUrl.includes("Applied")) {
                const paramDelimiter = "&";
                const startIndex = notification.callToActionUrl.indexOf(searchString);
                const endIndex = notification.callToActionUrl.indexOf(paramDelimiter, startIndex);
                const match = notification.callToActionUrl.match(/&jobId=([^&]+)/);
                const extractedString = notification.callToActionUrl.substring(startIndex, endIndex);
                const statusResponse = await handleJobsApiCall("A", match[1]);
                if (statusResponse[0]?.enterpriseProcessStatus == "R") {
                    const inputString = match[0];
                    let jobIde = inputString.replace("&jobId=", "");
                    let payLoadState = {
                        jobId: jobIde,
                        tabValue: match[1]
                    };
                    navigate(extractedString, { state: payLoadState });
                    handleClose3();
                    setIsLoading(false);
                    getUpdatedUnreadCount();
                } else {
                    danger("The notification you're trying to access has expired.");
                    handleClose3();
                    setIsLoading(false);
                    getUpdatedUnreadCount();
                    return;
                }
            }
        } else {
            const path = notification.callToActionUrl != "" ? new URL(notification.callToActionUrl).pathname : "";
            // const startIndex = notification.callToActionUrl.indexOf(searchString);
            // const extractedString = notification.callToActionUrl.substring(startIndex);
            if (notification.notificationValue?.includes("interview feedback") ||
             notification.notificationValue?.includes("received an interview invite") ||
             notification.notificationValue?.includes("rescheduled") ||
             notification.notificationValue?.includes("cancelled") ||
             notification.notificationValue?.includes("upcoming interview")) {
                interviewDetails(path, notification);
              } else if (notification.callToActionUrl?.includes("/message-list")) {
                let array = notification.callToActionUrl.split("?");
                if (array?.length > 1) {
                  let _searchParams = array[1].split("&");
                  let _candidateId = _searchParams[0].split("=")[1];
                  let _enterpriseId = _searchParams[1].split("=")[1];
                  ApiCalls.get(
                    "candidates/messages/0/500"
                  )
                  .then((response) => {
                    let isExpired = false;
                    if (response?.length) {
                        let _currentChat = response?.filter((_chat)=>(_chat.unreadCount > 0 && _chat.messageFrom === _candidateId && _chat.enterpriseId === _enterpriseId));
                        if (_currentChat?.length) {
                          let timestamp1 = moment(_currentChat[0].lastMessageTimestamp).format("YYYY-MM-DDTHH:mm");
                          let timestamp2 = moment(notification.createdTimestamp).utc().format("YYYY-MM-DDTHH:mm");
                          if (timestamp1 !== timestamp2) {
                            isExpired = true;
                            danger("The notification you're trying to access has expired.");
                          }
                        } else {
                          isExpired = true;
                          danger("The notification you're trying to access has expired.");
                        }
                    } else {
                      isExpired = true;
                      danger("The notification you're trying to access has expired.");
                    }
                    if (!isExpired) {
                      if (window.location.pathname.includes("/message-list")) {
                        navigate(path);
                        window.location.reload();
                      } else {
                        navigate(path);
                      }
                    }
                  }).catch((err)=>{});
                }
              } else if (notification.callToActionUrl?.includes("candidate/job-details/") && notification.notificationValue?.includes("shortlisted")) {
                let job_id = notification.callToActionUrl.split("job-details/")[1];
                ApiCalls.get(`${process.env.REACT_APP_API_URL}candidates/job/${job_id}`)
                .then((response) => {
                    if (response.enterpriseProcessStatus !== "S") {
                        danger("The notification you're trying to access has expired.");
                    } else {
                        if (window.location.pathname.includes("candidate/job-details/")) {
                            navigate(path);
                            window.location.reload();
                        } else {
                            navigate(path);
                        }
                    }
                }).catch((err)=>{});
              } else {
                navigate(path);
              };
            setIsLoading(false);
            getUpdatedUnreadCount();
            handleClose3();
        }
    };

    return (
        <>
            <Grid>
                <Button
                    id="basic-button3"
                    aria-controls={open3 ? "basic-menu3" : undefined}
                    aria-haspopup="true"
                    className="dropdown-arrow text-transform-none cursor-default position-issue pxy-8 icon-min-width3"
                    aria-expanded={open3 ? "true" : undefined}
                    onClick={handleClick3}
                    role="switch"
                    aria-checked={open3 ? "true" : "false"}
                    tabIndex={0}
                    disableRipple="true"
                >
                    {(notifications.length >= 1 && notifications.length <= 50) && (typeof notificationsCount === 'undefined' || (notificationsCount >= 0 && notificationsCount <= 50))&& (
                        <Box className="notify-badge">
                            <Badge
                                badgeContent={notificationsCount}
                                color="secondary"
                                className="notification-icon contrasts"
                            >
                                <Tooltip title="Notifications" placement="bottom">
                                    <img
                                        src={notificatioIcon}
                                        alt="Notification Icon"
                                        width="18px"
                                        height="18px"
                                        className="cursor-hover"
                                        onClick={handleNotificationRead}
                                        onKeyPress={handleNotificationRead}
                                    />
                                </Tooltip>
                            </Badge>
                        </Box>
                    )}

                    {notificationsCount > 50 && (
                        <Box className="notify-badge">
                            <Badge
                                badgeContent="50+"
                                color="secondary"
                                className="notification-icon contrasts"
                            >
                                <Tooltip title="Notifications" placement="bottom">
                                    <img
                                        src={notificatioIcon}
                                        alt="Notification Icon"
                                        width="18px"
                                        height="18px"
                                        className="cursor-hover"
                                        onClick={handleNotificationRead}
                                        onKeyPress={handleNotificationRead}
                                    />
                                </Tooltip>
                            </Badge>
                        </Box>
                    )}

                    {notifications.length < 1 && (
                        <Box className="display-flex">
                            <Tooltip title="Notifications" placement="bottom">
                                <img
                                    src={notificatioIcon}
                                    alt="Notification Icon"
                                    width="18px"
                                    height="18px"
                                    className="cursor-hover"
                                    onClick={handleNotificationRead}
                                    onKeyPress={handleNotificationRead}
                                />
                            </Tooltip>
                        </Box>
                    )}
                    {props.isMobile && <span className="pl-10 p-t-2 gray9 f-14">Notifications</span>}
                </Button>
                <Menu
                    id="basic-menu3"
                    anchorEl={anchorE3}
                    open={open3}
                    onClose={handleClose3}
                    disableScrollLock={true}
                    className="notification-body-width notification-hover mt-35"
                    PaperProps={{
                        style: {
                            width: 400,
                        },
                    }}
                    MenuListProps={{
                        "aria-labelledby": "basic-button3",
                    }}
                >
                    <Grid className="notification-body">
                    <Grid className="mb-10">
                        <Typography className="display-flex align-center justify-between">
                            <Typography className="pl-10 pr-10 py-10 h3-text heading-text-color">
                                Notifications
                            </Typography>
                            {notifications.length >= 1 && notificationsCount > 0 &&
                                <Typography className="f-14 link-color font-weight-500 pr-15" onClick={handleAllNotificationRead}>
                                    Mark all as read
                                </Typography>}
                        </Typography>
                        <Grid className="pl-15 display-flex align-center mb-10">
                            <Button
                              style={{ width: '80px', height: '25px' }}
                              className={`btn display-flex talent-pool-responsive-btn border-radius-100 font-weight-500 font-size-12 ${selectedbutton == "All" ? "all-unread-button-selected" : "all-unread-button"}`}
                              onClick={allnotificationslist}
                              value={"All"}
                            >
                              <span className="ml-20 mr-20">All</span>
                            </Button>
                            <Button
                              style={{ width: '80px', height: '25px' }}
                              className={`ml-8 btn display-flex talent-pool-responsive-btn border-radius-100 font-weight-500 font-size-12 ${selectedbutton == "Unread" ? "all-unread-button-selected" : "all-unread-button"}`}
                              onClick={getUnreadNotificationsDtoList}
                              value={"Unread"}
                            >
                              <span className="ml-20 mr-20">Unread</span>
                            </Button>
                        </Grid>
                        {notifications.length >= 1 ? (
                            notifications.map((notification, index) => {
                                // notification.callToActionUrl =
                                //   "candidate/interviewDetails/171";
                                return ((index < 5) ?
                                    <MenuItem
                                        className={`d-block p-l-0 p-r-0 p-t-0 pb-0 ${notification.notificationStatus == "U" && "bg-grey notifymenu-item"}`}
                                        key={index}
                                    >
                                        <Typography className={`display-flex justify-between border-bottom-gray5 pl-18 pr-18 pt-10 pb-10 ${notification.callToActionUrl && "cursor-hover"} ${index == 0 && "pt-15"} ${notification.notificationStatus == "U" && "secondary-bg-color justify-between display-flex"}`}
                                         onClick={() => notification.callToActionUrl && (appOrigin !== "N" ? handleNotificationNavigation(notification) : !notification.callToActionUrl.includes("/interviewDetails") && handleNotificationNavigation(notification))}>
                                            <Typography className="display-flex">
                                                <span>
                                                    <Typography className="notification-default-icon justify-center align-center display-flex">
                                                        <NotificationDefaultIcon
                                                            width="40px"
                                                            height="40px"
                                                            className="rect-svg-icons"
                                                        />
                                                        {/* {getValidFullName(fullname).trim().substring(0, 1)} */}
                                                    </Typography>
                                                </span>
                                                <span className="pl-10">
                                                    <Typography className="cursor-defaul break-space">
                                                        <Typography className="body-medium-text">
                                                            {notification.notificationValue}
                                                        </Typography>
                                                    </Typography>
                                                    <Typography className="cursor-default">
                                                        {formatDate(new Date()) ===
                                                            formatDate(notification?.createdTimestamp) ? (
                                                            <Typography className="supporting-text-light p-t-5">
                                                                {/* {moment(new Date()).startOf('hour').fromNow()} */}
                                                                {isMinutesOrHours(
                                                                    notification?.createdTimestamp
                                                                )}
                                                            </Typography>
                                                        ) : (
                                                            <Typography>
                                                                {formatDate(onedayBefore) ===
                                                                    formatDate(
                                                                        notification?.createdTimestamp
                                                                    ) ? (
                                                                    <Typography className="supporting-text-light p-t-5">
                                                                        {notification?.createdTimestamp ===
                                                                            null ||
                                                                            notification?.createdTimestamp === ""
                                                                            ? "-"
                                                                            : "Yesterday"}
                                                                    </Typography>
                                                                ) : (
                                                                    <>
                                                                        {formatDate(currentDateWeekFormat) <=
                                                                            formatDate(
                                                                                notification?.createdTimestamp
                                                                            ) ? (
                                                                            <Typography className="supporting-text-light p-t-5">
                                                                                {notification?.createdTimestamp ===
                                                                                    null ||
                                                                                    notification?.createdTimestamp ===
                                                                                    ""
                                                                                    ? "-"
                                                                                    : moment(
                                                                                        notification?.createdTimestamp
                                                                                    ).format("MMM DD, YYYY")}
                                                                            </Typography>
                                                                        ) : (
                                                                            <Typography className="supporting-text-light p-t-5">
                                                                                {notification?.createdTimestamp ===
                                                                                    null ||
                                                                                    notification?.createdTimestamp ===
                                                                                    ""
                                                                                    ? "-"
                                                                                    : moment(
                                                                                        notification?.createdTimestamp
                                                                                    ).format("MMM DD, YYYY")}
                                                                            </Typography>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </Typography>
                                                </span>
                                            </Typography>
                                            {notification.notificationStatus == "U" &&
                                                <Typography className="display-flex align-center ml-30">
                                                    <span className="unread-dot"></span>
                                                </Typography>}
                                        </Typography>
                                    </MenuItem>
                                    : ""
                                );
                            })
                        ) : (
                            <MenuItem className="d-block p-10 mt-20">
                                <Typography className="l-h-1 cursor-default text-center">
                                    <Typography>
                                        <NoNotificationIcon width={120} height={120} />
                                    </Typography>
                                    <Typography className="py-20">
                                        <span className="body-medium-normal py-20 font-weight-600">
                                            You don’t have any notifications for now
                                        </span>
                                    </Typography>
                                </Typography>
                            </MenuItem>
                        )}
                    </Grid>
                    </Grid>
                    {notifications.length >= 6 && (
                        <>
                            {/* <Divider /> */}
                            <Grid
                                tabIndex={-1}
                                className="link-color font-weight-600 f-14 align-item-center cursor-hover my-15 pxy-6-16 all-notify"
                                onClick={viewAllNotification}
                                onKeyPress={viewAllNotification}
                            >
                                View all notifications
                            </Grid>
                        </>
                    )}
                </Menu>
            </Grid>
            <NotificationList
                //handleNotificationDrawer={handleNotificationDrawer}
                notificationListDrawer={notificationListDrawer}
                setNotificationListDrawer={setNotificationListDrawer}
                notifications={notifications}
                notificationsCount={notificationsCount}
                closeBackpopups={props.viewAllNotification}
                getUnreadNotificationsDtoList={getUnreadNotificationsDtoList}
                allnotificationslist={allnotificationslist}
                setNotifications={setNotifications}
                selectedbutton={selectedbutton}
                setNotificationsCount={setNotificationsCount}
            />
        </>
    );
}

import { createSlice } from '@reduxjs/toolkit';
import ApiCalls from "../../api/customAxios";

const initialState = {
  candidate: {},
};

export const profileSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
      getCandidateRecode: (state, action) => {
          state.candidate = action.payload;
      },
}
});

// Action creators are generated for each case reducer function
export const { getCandidateRecode } = profileSlice.actions;

export default profileSlice.reducer;

import "crypto-js/lib-typedarrays";
import awsconfig from "../aws-exports";
import KeycloakService from "../keycloak";
import ApiCalls from "../api/customAxios";
import { useCookies } from "react-cookie";
import axios from "axios";

export const keycloakPostAuthSuccess = async () => {
  const userInfo = KeycloakService.userInfo();
  const dsaasCandidateId = userInfo?.dsaascandidateid;
  const talentPoolId = userInfo?.["custom:talentPoolId"] || null;
  const inviteeCandidateId = userInfo?.["custom:inviteeCandidateId"] || null;
  const domain = localStorage.getItem("domain");
  const sourceEnterpriseId = localStorage.getItem("sourceEnterpriseId");
  const queryParams = new URLSearchParams(window.location.search);
  if (talentPoolId && inviteeCandidateId) {
    try {
      const formData = new FormData();
      formData.append("id", inviteeCandidateId);
      await ApiCalls.put(`candidates/pool/priority/invitee`, formData);
      await ApiCalls.put(
        `candidates/pool/priority/${talentPoolId}/candidates`,
        { candidatesIds: [dsaasCandidateId], origin: "I" }
      );
      await KeycloakService.updateUserAccountInfo((accountInfo) => {
        delete accountInfo.attributes["custom:talentPoolId"];
      });
    } catch (err) {
      console.error("error completing invite candidate flow");
    }
  } else {
    let data = {};
    // updating origin and sourceEnterpriseId when signed up from career page
    if (domain && domain != null && sourceEnterpriseId && sourceEnterpriseId != null && localStorage.getItem("talentPoolInfo") == null) {
      data.origin = "R";
      data.sourceEnterpriseId = sourceEnterpriseId;
    } else if (localStorage.getItem("talentPoolInfo") != null && domain != null) {
      data.origin = "J";
      data.sourceEnterpriseId = sourceEnterpriseId;
    } else {
      data.origin = "P";
    }
    try {
      await ApiCalls.get('candidates/')
      .then(async (res) => {
        let skills = JSON?.parse(res.skillData);
          if (skills?.length) {
            localStorage.setItem("skillsCount", skills?.length);
          }
        if (!res.origin) {
          await ApiCalls.put(`candidates/`, data);
        } else if (res?.origin === "M") {
            await axios
              .get("https://ipapi.co/json/")
              .then(async (response) => {
                await ApiCalls.put(
                  `/candidates/update/region/${response?.data?.country_name}`
                )
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
        }
      });
    } catch (err) {}
  }
  // else if (domain != null && queryParams.get('exclusive-candidate')) {
  //   let data = {};
  //   data.origin = "R";
  //   data.sourceEnterpriseId = sourceEnterpriseId;
  //   try {
  //     await ApiCalls.put(`candidates/`, data);
  //     localStorage.removeItem("sourceEnterpriseId");
  //   } catch (err) {
  //     console.error("error exclusive candidates candidate flow");
  //   }
  // }
  await ApiCalls.post(`/candidates/chatbot/details`)
  .then((response) => {
    // setIsLoading(false);
  })
  .catch(function (error) {
  });
};

export const signIn = (username, password, appOrigin) => {
  const clientMetadata = {
    origin: process.env.REACT_APP_ORIGIN,
    clientMetadata:
      appOrigin === "N"
        ? "NEURODIVERSITY_CANDIDATE_VIEW"
        : appOrigin === "H"
        ? "HEALTHCARE_CANDIDATE_VIEW"
        : "GENERAL_CANDIDATE_VIEW",
  };
  return new Promise((resolve, reject) => {
    Auth.signIn(username, password, clientMetadata)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const changeUserPassword = (oldPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then((user) => {
      Auth.changePassword(user, oldPassword, newPassword)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
      });
  });
};

export const changeEmail = async (email) => {
  try {
    return await KeycloakService.updateUserAccountInfo((accountInfo) => {
      accountInfo.email = email;
      accountInfo.username = email;
      accountInfo.emailVerified = false;
    });
  } catch (err) {
    console.error("error changing email ->", err);
    throw err;
  }
};

export const changeName = async (name) => {
  try {
    return await KeycloakService.updateUserAccountInfo((accountInfo) => {
      accountInfo.firstName = name;
    });
  } catch (err) {
    console.error("error changing name ->", err);
    throw err;
  }
};

export const resendVerifyUserAttribute = () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then((user) => {
      Auth.verifyUserAttribute(user, "email")
        .then((res) => resolve(res))
        .catch((err) => reject(err));
      });
  });
};

export const signUp = (username, password, name, ipAddress, appOrigin) => {
  return new Promise((resolve, reject) => {
    const clientMetadata = {
      origin: process.env.REACT_APP_ORIGIN,
      clientMetadata:
        appOrigin === "N"
          ? "NEURODIVERSITY_CANDIDATE_VIEW"
          : appOrigin === "H"
          ? "HEALTHCARE_CANDIDATE_VIEW"
          : "GENERAL_CANDIDATE_VIEW",
    };
    Auth.signUp({
      username,
      password,
      attributes: {
        name,
        "custom:ipAddress": ipAddress,
        "custom:neuro_classification": appOrigin === "N" ? "ND" : "NT",
      },
      clientMetadata,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const confirmSignup = (username, otp) => {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(username, otp)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const verifyNewEmailOtp = (otp) => {
  return new Promise((resolve, reject) => {
    Auth.verifyCurrentUserAttributeSubmit("email", otp)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

// Send confirmation code to user's email
export const forgotPassword = (username, appOrigin) => {
  const clientMetadata = {
    origin: process.env.REACT_APP_ORIGIN,
    clientMetadata:
      appOrigin === "N"
        ? "NEURODIVERSITY_CANDIDATE_VIEW"
        : appOrigin === "H"
        ? "HEALTHCARE_CANDIDATE_VIEW"
        : "GENERAL_CANDIDATE_VIEW",
  };
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(username, clientMetadata)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const forceChangePassword = (username, tempPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, tempPassword).then((user) => {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        Auth.completeNewPassword(user, newPassword)
          .then((user) => {
            resolve(user);
          })
          .catch((err) => reject(err));
      }
    });
  });
};

// Collect confirmation code and new password, then
export const forgotPasswordSubmit = (username, code, password) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const resendSignup = (username, appOrigin) => {
  const clientMetadata = {
    origin: process.env.REACT_APP_ORIGIN,
    clientMetadata:
      appOrigin === "N"
        ? "NEURODIVERSITY_CANDIDATE_VIEW"
        : appOrigin === "H"
        ? "HEALTHCARE_CANDIDATE_VIEW"
        : "GENERAL_CANDIDATE_VIEW",
  };
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(username, clientMetadata)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const isSessionValid = async () => {
  return KeycloakService.isLoggedIn();
};

export const logout = async () => {
  try {
    if (KeycloakService.getToken()) {
      localStorage.clear();
      await KeycloakService.doLogout({ redirectUri: `${window.location.origin}/candidate/dashboard` });
      await KeycloakService.doLogin({
        redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/dashboard`),
      });
    }
    sessionStorage.clear();
    document.cookie.split(";").forEach(function (cookie) {
      let ck = cookie.trim().split("=");
      if (ck.length > 1 && String(ck[1]).includes("skipped")) {
        document.cookie = `${ck[0]}=; Max-Age=0; path=/;`;
      }
    });
  } catch {
    return;
  }
};

export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const parseJwt = (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export function checkIfUserWasInactive() {
  // return authorization header with jwt token
  return new Promise((resolve, reject) => {
    resolve(KeycloakService.userInfo()?.wasUserInactive || false);
  });
}

export function getAccessToken() {
  // return authorization header with jwt token
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then((res) => {
      res.getSession((err, session) => {
        if (err) {
          logout();
        } else if (!session.isValid()) {
          logout();
        } else {
          const accessToken = session.getAccessToken().getJwtToken();
          resolve(accessToken);
        }
      });
    });
  });
}
export function getCurrentUserToken() {
  // return authorization header with jwt token
  return new Promise((resolve, reject) => {
    if (KeycloakService.isLoggedIn()) {
      return resolve(KeycloakService.getToken());
    }

    // reject(new Error('user is not logged in'));
  });
}
export const checkCurrentUserSession = () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        res.getSession((err, session) => {
          if (err) {
            logout().then((res) => window.location.reload());
          } else if (!session.isValid()) {
            logout().then((res) => window.location.reload());
          } else {
            resolve(session);
          }
        });
      })
      .catch((error) => {
        console.log("this is the error while get authenticated user", error);
        // logout().then((res) => window.location.reload());
      });
  });
};
const extractValueFromCookie = (cookieName, cookieString) => {
  const matchedCookie = cookieString.split(';').find((cookie) => cookie.includes(cookieName));
  if (matchedCookie) {
    return matchedCookie.trim().split('=')[1];
  }
  return null;
};
export const checkForCookiePopUp = (location) => {
  //if pages are cookiesPolicy, termsOfUse or privacyPolicy we are hiding cookiePopup
  if (location.pathname.includes("/cookiepolicy") || location.pathname.includes("/termsofuse") || location.pathname.includes("/privacypolicy") || location.pathname.includes("/ukprivacypolicy")) {
    return false;
    //if page is LaunchPage, we are checking if cookie is not Present then we are showing cookiePopup
  } else if (location.pathname == "/" && !extractValueFromCookie('cookieConsent', "accepted") && !KeycloakService.isLoggedIn()) {
    console.log("eee");
    return true;
    //if page is not public page, we are checking if cookie is Present or not then we are not showing Cookie Popup
  } else if (!KeycloakService.isLoggedIn() && !extractValueFromCookie('cookieConsent', "accepted") && (location.pathname.includes("/candidate") || location.pathname.includes("/signin") || location.pathname.includes("/signup"))) {
    return false;
    //if page is public page, we are checking if cookie is Present or not then we are showing Cookie Popup
  } else if (!KeycloakService.isLoggedIn() && !extractValueFromCookie('cookieConsent', "accepted") && !location.pathname.includes("/candidate")) {
    return true;
    //if page is not public page, we are checking if cookie is Present or not then we are showing Cookie Popup
  } else if (KeycloakService.isLoggedIn() && !extractValueFromCookie('cookieConsent', "accepted")) {
    return true;
  }
};
export const functiondisable = () => {
  // To get the scroll position of current webpage
  let TopScroll = window.pageYOffset || document.documentElement.scrollTop;
  let LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;
  // if scroll happens, set it to the previous value
  window.onscroll = function () {
    window.scrollTo(LeftScroll, TopScroll);
  };
};

export const functionenable = () => {
  window.onscroll = function () {};
};

export const navigateToSSOSignIn = () => {
  window.open(
    `https://${process.env.REACT_APP_AWS_USER_POOL_DOMAIN}/login?redirect_uri=${process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}&response_type=code&client_id=${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}&identity_provider=COGNITO&scope=phone+email+openid+profile+aws.cognito.signin.user.admin`,
    "_self"
  );
};

export const navigateToSSOSignInWithState = (state) => {
  window.open(
    `https://${process.env.REACT_APP_AWS_USER_POOL_DOMAIN}/login?redirect_uri=${process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}&response_type=code&client_id=${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}&identity_provider=COGNITO&scope=phone+email+openid+profile+aws.cognito.signin.user.admin&state=${state}`,
    "_self"
  );
};

export const navigateToSSOSignUp = () => {
  window.open(
    `https://${process.env.REACT_APP_AWS_USER_POOL_DOMAIN}/signup?redirect_uri=${process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}&response_type=code&client_id=${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}&identity_provider=COGNITO&scope=phone+email+openid+profile+aws.cognito.signin.user.admin`,
    "_self"
  );
};

export const navigateEnterpriseSignIn = () => {
  window.open(
    `https://${process.env.REACT_APP_AWS_USER_ENTERPRISE_POOL_DOMAIN}/signup?redirect_uri=${process.env.REACT_APP_AWS_USER_ENTERPRISE_POOL_REDIRECT_SIGN_IN}&response_type=code&client_id=${process.env.REACT_APP_AWS_USER_ENTERPRISE_POOL_WEB_CLIENT_ID}&identity_provider=COGNITO&scope=phone+email+openid+profile+aws.cognito.signin.user.admin`,
    "_self"
  );
};

export const navigateToEnterprise = () => {
  window.open(
    `${process.env.REACT_APP_AWS_USER_ENTERPRISE_POOL_REDIRECT_SIGN_IN}/signin`,
    "_self"
  );
};

export const getThemeAsBase64 = () => {
  let result = 'default';

  const themeLS = localStorage.getItem('theme_obj');
  if (themeLS) {
    const theme = JSON.parse(themeLS);
    theme.logo = localStorage.getItem('domainNavBarLogoSignedUrl');

    if (localStorage.getItem("domain") != null && localStorage.getItem("talentPoolInfo") == null) {
      theme.sourceEnterpriseId = localStorage.getItem('sourceEnterpriseId');
      theme.origin = "R";
      theme.careerPortalFavIcon = localStorage.getItem('careerPortalFavIcon');
    } else if (localStorage.getItem("talentPoolInfo") != null && localStorage.getItem("domain") != null) {
      theme.sourceEnterpriseId = localStorage.getItem('sourceEnterpriseId');
      theme.origin = "J";
      theme.careerPortalFavIcon = localStorage.getItem('careerPortalFavIcon');
    }
    if (localStorage.getItem("isReferredOrigin") != null) {
      delete theme.origin;
      delete theme.sourceEnterpriseId;
    };
    result = btoa(JSON.stringify(theme));
  }

  return result;
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const addDataToKeycloakRedirectURI = (redirectUri = window.location.href, exclusiveCandidate) => {
  const url = new URL(redirectUri);
  const theme = getThemeAsBase64();
  if (theme) {
    url.searchParams.append('dsaas_enterprise_theme', theme);
  };
  // const cookieConsent = getCookie('cookieConsent');
  //   if (!cookieConsent) {
  //       url.searchParams.append("cookieContent", false);
  //   } else {
  //       url.searchParams.append("cookieContent", true);
  //   }
    return url.toString();
  // if (localStorage.getItem("domain") != null && exclusiveCandidate) {
  //   url.searchParams.append('exclusive-candidate', true);
  // };
};

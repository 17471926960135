import { Grid } from "@mui/material";
import { useLottie } from "lottie-react";
import React from "react";
import groovyWalkAnimation from "./../assets/loader.json";
import { colorify, flatten, getColors } from 'lottie-colorify';

export default function Loader() {
  const convert3DigitHexTo6Digit = (hexcolor) => {
    if (hexcolor.length === 4) {
      return hexcolor.substring(1, 4).split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    return hexcolor;
  };
  let themeColor = (window.location.pathname === "/") ? "#0071E3" : convert3DigitHexTo6Digit(localStorage.getItem("primaryColor") != null ? localStorage.getItem("primaryColor") : getComputedStyle(document.documentElement).getPropertyValue('--primary-color'));

  const adjustColor = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, (color) => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  };

  const options = {
    // animationData: groovyWalkAnimation,
    animationData: colorify([themeColor, adjustColor(themeColor, 40), adjustColor(themeColor, 70), adjustColor(themeColor, 95)], groovyWalkAnimation),
    loop: true,
    autoplay: true,
  };
  const { View } = useLottie(options);

  return (
    <>
      <Grid className="background-blur"></Grid>
      <Grid item lg={12} className="loader">
        <Grid item lg={12} className="">
          {View}
        </Grid>
      </Grid>
    </>
  );
}

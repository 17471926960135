import react, { useState, useEffect } from "react";
import { ReactComponent as AccessiblityIcon } from "../assets/images/accessibility.svg";
import { ReactComponent as IconAccessibility } from "../assets/images/icon_accessibility.svg";
import { ReactComponent as BionicIcon } from "../assets/images/Subtract.svg";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  Popover,
  Slide,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { ReactComponent as CrossIcon } from "../assets/images/x-mark.svg";
import { ReactComponent as BoldIcon } from "../assets/images/boldIcon.svg";
import { ReactComponent as UnderLineIcon } from "../assets/images/underlineIcon.svg";
import Slider from "@mui/material/Slider";
import ApiCalls from "../api/customAxios";
import { success } from "./shared/messageBox";
import _ from "lodash";
import Loader from "../pages/Loader.js";
import { useSelector } from "react-redux";
import KeycloakService from "../keycloak";

const Recite = () => {
  const serviceKey = process.env.REACT_APP_RECITE_ME_SERVICE_KEY;
  const appOrigin = useSelector((state) => state.location.origin);
  const candidateInfo = useSelector((state) => state.profile.candidate);
  const [open, setOpen] = useState(false);
  const URLPath = location.pathname;
  // const [openPopover, setopenPopover] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [accessibilityToggle, setAccessibilityToggle] = useState(false);
  const [bionicToggle, setBionicToggle] = useState(
    localStorage.getItem("isBionic") ? localStorage.getItem("isBionic") : false
  );
  const [fixationSlider, setFixationSlider] = useState(3);
  const [fontSlider, setFontSlider] = useState(16);
  const [selectedColor, setSelectedColor] = useState("#101828");
  const [bioninSelect, setBioninSelect] = useState("bold");
  const openPopover = Boolean(anchorEl2);
  const [isFirstRender, setIsFirstRender] = useState(true);
  let isAutenticated = KeycloakService.isLoggedIn();
  const [activebutton, setIsActiveButton] = useState("#101828");
  const [isSaved, setIsSaved] = useState(false);
  const [onToggleChanges, setOnToggleChanges] = useState({});
  const [shouldClosePopover, setShouldClosePopover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const ReciteListerHandler = () => {
  //     setOpen(true);
  // };
  const handleOpenPopover = (event) => {
    if (isAutenticated) {
      ApiCalls.get("candidates/accessibility").then(async (res) => {
        // const res = await response;
        if (res?.accessibility == "Y") {
          await setOpen(true);
        }
        if (
          res?.bionicReadingConfiguration !== null &&
          res?.bionicReadingConfiguration !== ""
        ) {
          let parsedObject = JSON.parse(res?.bionicReadingConfiguration);
          setFontSlider(parsedObject?.fontSize);
          setFixationSlider(parsedObject?.fixation);
          setSelectedColor(parsedObject?.color);
          setBioninSelect(parsedObject?.bionicSelect);
          setBionicToggle(true);
          setIsSaved(true);
          setOnToggleChanges(parsedObject);
        } else {
          setBionicToggle(false);
          setFontSlider(16);
          setFixationSlider(3);
          setSelectedColor("#101828");
          setBioninSelect("bold");
          localStorage.removeItem("isBionic");
          localStorage.removeItem("bionic");
          window.dispatchEvent(new Event("storage"));
        }
      });
    }
    setAnchorEl2(event.currentTarget);
  };
  const handleAccessibilityToggle = (event) => {
    setAnchorEl2(null);
    setOpen(event.target.checked);
    if (isAutenticated) {
      handleSubmitBionic(event.target.checked);
    }
    if (event.target.checked == false) {
      setIsLoading(true);
      if (!isAutenticated) {
        var recite = window.Recite;
        recite?.disable(true);
        // document.querySelector("#recite-modal-button-ok")?.click();
      }
    }
  };
  const handleBionicToggle = (event) => {
    if (!event.target.checked) {
      localStorage.removeItem("isBionic");
      localStorage.removeItem("bionic");
      window.dispatchEvent(new Event("storage"));
      if (isAutenticated) {
        setIsSaved(false);
        const payloadObj = {
          // accessibility: open ? "Y" : "N",
          id: candidateInfo?.id,
          bionicReadingConfiguration: "",
        };
        ApiCalls.put("candidates/", payloadObj).then((res) => {
          if (res) {
            success("Your preferences are saved.");
          }
        });
      }
      setTimeout(() => {
        handleClosePopover();
      }, 500);
      setBionicToggle(event.target.checked);
    } else {
      setBionicToggle(event.target.checked);
    }
  };
  const handleClosePopover = () => {
    if (bionicToggle && isAutenticated && !_.isEmpty(onToggleChanges)) {
      if (onToggleChanges.fontSize != fontSlider) {
        setFontSlider(onToggleChanges.fontSize);
      }
      if (onToggleChanges.fixation != fixationSlider) {
        setFixationSlider(onToggleChanges.fixation);
      }
      if (onToggleChanges.color != selectedColor) {
        setSelectedColor(onToggleChanges.color);
      }
      if (onToggleChanges.bionicSelect != bioninSelect) {
        setBioninSelect(onToggleChanges.bionicSelect);
      }
    }
    if (isAutenticated && !isSaved) {
      setBionicToggle(false);
      setFixationSlider(3);
      setFontSlider(16);
      setSelectedColor("#101828");
      setBioninSelect("bold");
      localStorage.removeItem("isBionic");
      localStorage.removeItem("bionic");
      window.dispatchEvent(new Event("storage"));
    }
    setAnchorEl2(null);
  };
  const handleFixationChanges = (event, newvalue) => {
    setFixationSlider(newvalue);
  };
  const handleFontChanges = (event, newvalue) => {
    setFontSlider(newvalue);
  };
  const handleColorChange = (event) => {
    if (event.target.value == selectedColor) {
      return;
    }
    setSelectedColor(event.target.value);
  };
  const resetToDefaultValues = () => {
    setFixationSlider(3);
    setFontSlider(16);
    setSelectedColor("#101828");
    setBioninSelect("bold");
  };
  const handleSubmitBionic = (value) => {
    const bionicObj = {
      fixation: fixationSlider,
      fontSize: fontSlider,
      color: selectedColor,
      bionicSelect: bioninSelect,
    };
    let stringfyBionicObj = JSON.stringify(bionicObj);
    const payloadObj = {
      id: candidateInfo?.id,
      accessibility:
        typeof value == "boolean" ? (value ? "Y" : "N") : open ? "Y" : "N",
      // accessibility: open ? "Y" : "N",
      bionicReadingConfiguration: bionicToggle ? stringfyBionicObj : "",
    };
    if (!bionicToggle) {
      localStorage.removeItem("isBionic");
      setIsSaved(false);
    } else {
      setIsSaved(true);
      setOnToggleChanges(bionicObj);
      localStorage.setItem("isBionic", true);
      localStorage.setItem("bionic", stringfyBionicObj);
      setShouldClosePopover(true);
    }
    ApiCalls.put("candidates/", payloadObj).then((res) => {
      if (res) {
        if (value == false) {
          var recite = window.Recite;
          recite?.disable(true);
          // document.querySelector("#recite-modal-button-ok")?.click();
        }
        if (value == true || bionicToggle) {
          success("Your preferences are saved.");
        }
        // handleClosePopover();
      }
    });
  };
  const handleBionicSelect = (value) => {
    if (value === bioninSelect) {
      return;
    }
    setBioninSelect(value);
  };
  useEffect(() => {
    // if (openPopover == true) {
    //     if (localStorage.getItem("isBionic")) {
    //         let fixation = JSON.parse(localStorage.getItem("bionic"));
    //         setFontSlider(fixation?.font);
    //         setFixationSlider(fixation?.fixation);
    //         setSelectedColor(fixation?.color);
    //         setBioninSelect(fixation?.bionicSelect);
    //         setBionicToggle(true);
    //     }
    // }
    if (document.getElementById("recite-header") != null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openPopover]);
  useEffect(() => {
    if (shouldClosePopover) {
      handleClosePopover();
      setShouldClosePopover(false); // Reset the state to allow potential future updates
    }
  }, [shouldClosePopover]);
  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = `var serviceUrl = "//auapi.reciteme.com/asset/js?key=";
        var serviceKey ='${serviceKey?.replace(/[\u200B-\u200D\uFEFF]/g, "")}';
        var options = {
            //issue 7. adding options for zoomThreshold break point to window
            Controls: {
                Core: {
                    zoomThreshold: 100
                }
            }
        }
        var autoLoad = false;
        var enableFragment = "#reciteEnable";
        var loaded = [], frag = !1; window.location.hash === enableFragment && (frag = !0); function loadScript(c, b) { var a = document.createElement("script"); a.type = "text/javascript"; a.readyState ? a.onreadystatechange = function () { if ("loaded" == a.readyState || "complete" == a.readyState) a.onreadystatechange = null, void 0 != b && b() } : void 0 != b && (a.onload = function () { b() }); a.src = c; document.getElementsByTagName("head")[0].appendChild(a) }
    function _rc(c) { c += "="; for (var b = document.cookie.split(";"), a = 0; a < b.length; a++) { for (var d = b[a]; " " == d.charAt(0);)d = d.substring(1, d.length); if (0 == d.indexOf(c)) return d.substring(c.length, d.length) } return null } function loadService(c) {
        for (var b = serviceUrl + serviceKey, a = 0; a < loaded.length; a++)if (loaded[a] == b) return; loaded.push(b);
        loadScript(serviceUrl + serviceKey, function () { "function" === typeof _reciteLoaded && _reciteLoaded(); "function" == typeof c && c(); Recite.load(options); Recite.Event.subscribe("Recite:load", function () { Recite.enable() }) })
    } "true" == _rc("Recite.Persist") && loadService(); if (autoLoad && "false" != _rc("Recite.Persist") || frag) document.addEventListener ? document.addEventListener("DOMContentLoaded", function (c) { loadService() }) : loadService();
    function _reciteLoaded() {
        //issue 8. Magnifying glass is not showing proper element position in some screens. Seems to be working as the mag does. could remove?
        Recite.Event.subscribe("Controls:loaded", function () {
            // _removeBtns(["magnifier"]);
            //restricting zoom
            document.querySelectorAll(".recite-sprite-button-font-plus")[0].addEventListener('click',function(e){
                style = getComputedStyle(document.querySelector('#recite-wrapper'));
                if (style.zoom >= 1.3) {
                    e.target.setAttribute("disabled", "true");
                }
                document.querySelectorAll(".navbar-fixed-new")[0].setAttribute("style", "top:43px !important");
                document.querySelectorAll(".navbar-fixed-new")[0].style.overflow = "auto";
                document.querySelectorAll(".accessibility-container")[0].setAttribute("style", "width:1440px !important");
            });
              document.querySelectorAll(".recite-sprite-button-reset")[0].addEventListener('click',function(e){
                    document.querySelectorAll(".recite-sprite-button-font-plus")[0]?.removeAttribute("disabled");
                    document.querySelectorAll(".navbar-fixed-new")[0].setAttribute("style", "top:60px !important")
                    document.querySelectorAll(".navbar-fixed-new")[0].style.overflow = "auto";
                    document.querySelectorAll(".accessibility-container")[0].setAttribute("style", "width:1440px !important");
            });
              document.querySelectorAll(".recite-sprite-button-font-minus")[0].addEventListener('click',function(e){
                document.querySelectorAll(".recite-sprite-button-font-plus")[0]?.removeAttribute("disabled");
                document.querySelectorAll(".navbar-fixed-new")[0].setAttribute("style", "top:50px !important")
                document.querySelectorAll(".navbar-fixed-new")[0].style.overflow = "auto";
                document.querySelectorAll(".accessibility-container")[0].setAttribute("style", "width:1440px !important");
          });
          //issue 2. Nav Bar Styles Behind Recite - race con maybe?
          setTimeout(() => {
            document.querySelectorAll(".navbar-fixed-new")[0]?.setAttribute("style", "top:60px !important");
            if (document.querySelector('#recite-close')) {
                document.querySelector('#recite-close').removeAttribute("onclick");
            }
          }, 500);
            // var topNav = document.querySelectorAll("div div.MuiGrid-root.navbar-fixed-new")
            // for (var i = 0; i < topNav.length; i++) {
            //     if (topNav[i]) {
            //         topNav[i].setAttribute("style", "top:60px !important")
            //     }
            // }
            //issue 3. Ignore Overlay El
            var ignoreOverlayEl = document.querySelectorAll('div[style="position: fixed; z-index: 9999; inset: 76px 16px 16px; pointer-events: none;"]');
            for (var i = 0; i < ignoreOverlayEl.length; i++) {
                if (ignoreOverlayEl[i]) {
                    ignoreOverlayEl[i].classList += " recite-ignore-styles-background";
                }
            }
            //issue 4. aparently padding at bottom of toolbar is causing scrolling issues? i cant replicate, may need some direction to recreate this- but we can remove that style prop without causing issues anwyays
            var reciteToolbarStyle = document.querySelectorAll(".recite-controls");
            for (var i = 0; i < reciteToolbarStyle.length; i++) {
                if (reciteToolbarStyle[i]) {
                    reciteToolbarStyle[i].style.paddingBottom = "0px !important"
                }
            }
            //5. The changes made to Recite Me are not reflecting for all modals and dropdowns (Material UI).
            //the only modal i've seen is by clicking send message, this pops up a modal, which contains 3 lines of text elements & one textarea which we would not work with. For DPA
        })
        //modal issue: adding mutation observer to catch any dom mutations
                var targetNode = document.body;
            var observer = new MutationObserver(function (mutationsList) {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        for (let node of mutation.addedNodes) {
                            if (node.classList && node.classList.contains('MuiModal-root')) {

                                Recite.Parser.Html.parse(node);
                                Recite.PlayerControls.Tooltip.invoke(node);

                            }
                        }
                    }
                }
            });
            var observerConfig = { childList: true, subtree: true };
            observer.observe(targetNode, observerConfig);
    }
    //remove function, lives outside of recite loaded and called inside.
    function _removeBtns(btns) {
        Recite.Util.each(btns, function (id, btn) {
            Recite.Util.each(Recite.Sizzle(".recite-sprite-button-" + btn),
                function (id, element) {
                    element.remove();
                });
        });
    }`;
    document.head.appendChild(s);
    if (isAutenticated) {
      ApiCalls.get("candidates/accessibility").then(async (res) => {
        // const res = await response;
        if (res?.accessibility == "Y") {
          await setOpen(true);
        }
        if (
          res?.bionicReadingConfiguration !== null &&
          res?.bionicReadingConfiguration !== ""
        ) {
          let parsedObject = JSON.parse(res?.bionicReadingConfiguration);
          setFontSlider(parsedObject?.fontSize);
          setFixationSlider(parsedObject?.fixation);
          setSelectedColor(parsedObject?.color);
          setBioninSelect(parsedObject?.bionicSelect);
          setBionicToggle(true);
          setIsSaved(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (
      window["loadService"] != undefined &&
      open == true &&
      anchorEl2 == null
    ) {
      setTimeout(() => {
        window["loadService"].call();
      }, 1000);
    }
  }, [open]);
  useEffect(() => {
    if (bionicToggle == true) {
      const obj = {
        fixation: fixationSlider,
        font: fontSlider,
        color: selectedColor,
        bionicSelect: bioninSelect,
      };
      const stringfyObj = JSON.stringify(obj);
      localStorage.setItem("isBionic", true);
      localStorage.setItem("bionic", stringfyObj);
      window.dispatchEvent(new Event("storage"));
    }
  }, [bionicToggle]);
  useEffect(() => {
    if (isFirstRender) {
      if (localStorage.getItem("isBionic") && localStorage.getItem("bionic")) {
        setIsFirstRender(false);
        return;
      } else {
        const obj = {
          fixation: fixationSlider,
          font: fontSlider,
          color: selectedColor,
          bionicSelect: bioninSelect,
        };
        const stringfyObj = JSON.stringify(obj);
        localStorage.setItem("bionic", stringfyObj);
        window.dispatchEvent(new Event("storage"));
        setIsFirstRender(false);
      }
    } else {
      const obj = {
        fixation: fixationSlider,
        font: fontSlider,
        color: selectedColor,
        bionicSelect: bioninSelect,
      };
      const stringfyObj = JSON.stringify(obj);
      localStorage.setItem("bionic", stringfyObj);
      window.dispatchEvent(new Event("storage"));
    }
  }, [fontSlider, fixationSlider, selectedColor, bioninSelect]);
  setTimeout(() => {
    document.querySelector("#recite-close")?.addEventListener("click", () => {
      setIsLoading(true);
      // document.querySelector("#recite-modal").style.display = "none";
      // document.querySelector(".recite-fade").style.opacity = 1;
      if (isAutenticated) {
        handleSubmitBionic(false);
      } else {
        var recite = window.Recite;
        recite?.disable(true);
        // document.querySelector("#recite-modal-button-ok")?.click();
      }
      // document.querySelector("#recite-modal-button-ok")?.addEventListener("click", () => {
      //     handleSubmitBionic(false);
      // });
    });
  }, 3000);

  return (
    <>
      {isLoading && <Loader />}
      <div className="accessibility-new">
        <Button
          id="basic-button-menu"
          className="cursor-hover btn-accessibility"
          tabIndex={0}
          disableRipple="true"
          // onClick={() => ReciteListerHandler()}
          onClick={(e) => handleOpenPopover(e)}
        >
          {openPopover ? (
            <CrossIcon
              className="icon-display crossIcon-bionic cursor-hover"
              onClick={handleClosePopover}
            />
          ) : (
            <>
            {((URLPath != '/candidate/my-referrals') && (URLPath != '/candidate/analytics') && (URLPath != '/candidate/interviews')) &&
              <AccessiblityIcon className="icon-display" />
            }
            </>
          )}
          {/* <HoverIcon className='icon-display1'/> */}
        </Button>
      </div>
      <Popover
        id="simple-popover-list1"
        open={openPopover}
        anchorEl={anchorEl2}
        disableRestoreFocus
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        className={`${
          appOrigin === "N" ? "access-popover-neuro" : "access-popover"
        }`}
      >
        <Grid className="bionic-popover d-none">
          <Grid className="display-flex justify-between">
            <Typography className="mr-20 display-flex align-center">
              <IconAccessibility className="m-r-5" />
              <Typography className="heading-text-color font-weight-500 f-14">
                Accessibility <span className="f-10">(Work In Progress)</span>
              </Typography>
            </Typography>
            <Typography className="switch-case-section">
              <Switch
                checked={open}
                onChange={handleAccessibilityToggle}
                inputProps={{ "aria-label": "controlled" }}
                className={`${window.location.pathname === "/" ? "home-page-switch-color" : ""}`}
              />
            </Typography>
          </Grid>
          <Typography className="gray7 f-12 font-weight-400 m-t-5">
            It will open a tool which allows you to make website more accessible
            and usable for people with disabilities.
          </Typography>
        </Grid>
        <Grid className="m-t-2 bionic-popover">
          <Grid className="display-flex justify-between">
            <Typography className="mr-20 display-flex align-center">
              <BionicIcon className="m-r-5" width={18} height={18} />
              <Typography className="gray9 font-weight-500 f-14">
                Bionic Reading
              </Typography>
            </Typography>
            <Typography className="switch-case-section">
              <Switch
                checked={bionicToggle}
                onChange={handleBionicToggle}
                inputProps={{ "aria-label": "controlled" }}
                className={`${window.location.pathname === "/" ? "home-page-switch-color" : ""}`}
              />
            </Typography>
          </Grid>
          <Typography className="gray7 f-12 font-weight-400 m-t-5 p-b-5">
            Bionic reading helps you in reading faster, better & more focused.
          </Typography>
        </Grid>
        {bionicToggle && (
          <>
            <Grid className="bionic-popover">
              <Grid className="display-flex justify-between">
                <Typography className="mr-20 display-flex align-center">
                  <Typography className="gray9 font-weight-400 f-14 m-b-5">
                    Fixation
                  </Typography>
                </Typography>
                <Typography className="">
                  <span className="bionic-count">
                    <TextField
                      type="search"
                      className="bionic-rate m-t-0 m-b-0"
                      variant="outlined"
                      margin="normal"
                      // onChange={(e) => {
                      // }}
                      value={fixationSlider}
                      disabled
                      style={{
                        width: "35px",
                        fontSize: "12px",
                      }}
                    />
                  </span>
                </Typography>
              </Grid>
              <Typography className="gray7 f-12 font-weight-400 m-t-5 ml-10">
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={fixationSlider}
                  onChange={handleFixationChanges}
                  disabled={bionicToggle ? false : true}
                  onChangeCommitted={handleFixationChanges}
                  valueLabelDisplay="auto"
                  className={bionicToggle ? `${window.location.pathname === "/" ? "home-page-link-color" : "link-color"}` : null}
                  // getAriaValueText={valuetext}
                  // valueLabelFormat={valueLabelFormat}
                  min={1}
                  max={5}
                />
              </Typography>
            </Grid>
            <Grid className="bionic-popover">
              <Grid className="display-flex justify-between">
                <Typography className="mr-20 display-flex align-center">
                  <Typography className="gray9 font-weight-400 f-14 m-b-5">
                    Font Size
                  </Typography>
                </Typography>
                <Typography className="">
                  <span className="bionic-count">
                    <TextField
                      type="search"
                      className="bionic-rate m-t-0 m-b-0"
                      variant="outlined"
                      margin="normal"
                      // onChange={(e) => {
                      // }}
                      value={fontSlider}
                      disabled
                      style={{
                        width: "35px",
                        fontSize: "12px",
                      }}
                    />
                  </span>
                </Typography>
              </Grid>
              <Typography className="gray7 f-12 font-weight-400 m-t-5 ml-10">
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={fontSlider}
                  onChange={handleFontChanges}
                  onChangeCommitted={handleFontChanges}
                  disabled={bionicToggle ? false : true}
                  valueLabelDisplay="auto"
                  className={bionicToggle ? `${window.location.pathname === "/" ? "home-page-link-color" : "link-color"}` : null}
                  // getAriaValueText={valuetext}
                  // valueLabelFormat={valueLabelFormat}
                  min={16}
                  max={20}
                />
              </Typography>
            </Grid>
            <Grid className="bionic-popover">
              <Grid className="display-flex justify-between">
                <Typography className="mr-20 display-flex align-center">
                  <Typography className="gray9 font-weight-400 f-14">
                    Color Scheme
                  </Typography>
                </Typography>
              </Grid>
              <Grid className="mt-10 display-flex justify-between">
                <Typography className="mr-20 display-flex align-center bionic-btn">
                  <ToggleButtonGroup
                    value={selectedColor}
                    exclusive
                    onChange={handleColorChange}
                  >
                    <ToggleButton
                      value="#101828"
                      className={`${
                        selectedColor === "#101828"
                          ? "active-black-button"
                          : "bionic-black"
                      }`}
                      variant="contained"
                      size="small"
                      disabled={bionicToggle ? false : true}
                    ></ToggleButton>
                    <ToggleButton
                      value="#6941C6"
                      className={`${
                        selectedColor === "#6941C6"
                          ? "active-pink-button"
                          : "bionic-pink"
                      }`}
                      variant="contained"
                      size="small"
                      disabled={bionicToggle ? false : true}
                    ></ToggleButton>
                    <ToggleButton
                      value="#0071E3"
                      className={`${
                        selectedColor === "#0071E3"
                          ? "active-blue-button"
                          : "bionic-blue"
                      }`}
                      variant="contained"
                      size="small"
                      disabled={bionicToggle ? false : true}
                    ></ToggleButton>
                    <ToggleButton
                      value="#1A7FAE"
                      className={`${
                        selectedColor === "#1A7FAE"
                          ? "active-snow-button"
                          : "bionic-snow"
                      }`}
                      variant="contained"
                      size="small"
                      disabled={bionicToggle ? false : true}
                    ></ToggleButton>
                    <ToggleButton
                      value="#027A48"
                      className={`${
                        selectedColor === "#027A48"
                          ? "active-green-button"
                          : "bionic-green"
                      }`}
                      variant="contained"
                      size="small"
                      disabled={bionicToggle ? false : true}
                    ></ToggleButton>
                    <ToggleButton
                      value="#B42318"
                      className={`${
                        selectedColor === "#B42318"
                          ? "active-maroon-button"
                          : "bionic-maroon"
                      }`}
                      variant="contained"
                      size="small"
                      disabled={bionicToggle ? false : true}
                    ></ToggleButton>
                  </ToggleButtonGroup>
                </Typography>
              </Grid>
            </Grid>
            <Grid className="bionic-popover">
              <Grid className="display-flex justify-between">
                <Typography className="mr-20 display-flex align-center bionic-bold bionic-save">
                  <Button
                    className={`${
                      bioninSelect === "bold"
                        ? "active-gray-button"
                        : "bionic-gray"
                    }`}
                    onClick={() => handleBionicSelect("bold")}
                    disabled={!bionicToggle}
                  >
                    <BoldIcon />
                  </Button>
                  <Button
                    className={`${
                      bioninSelect === "underline"
                        ? "active-gray-button"
                        : "bionic-gray"
                    }`}
                    onClick={() => handleBionicSelect("underline")}
                    disabled={!bionicToggle}
                  >
                    <UnderLineIcon />
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {isAutenticated && bionicToggle && (
          <Grid className="bionic-popover mb-10" align="right">
            <Typography className="mr-20 display-flex align-center justify-end text-transform bionic-save">
              <Button
                className="link-color m-r-5"
                onClick={resetToDefaultValues}
              >
                Reset to default
              </Button>
              <Button
                className="btn primary-button bionic-blue"
                variant="contained"
                size="large"
                onClick={handleSubmitBionic}
              >
                Save
              </Button>
            </Typography>
          </Grid>
        )}
      </Popover>
    </>
  );
};

export default Recite;

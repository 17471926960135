import React from "react";
// import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import Typography from "@mui/material/Typography";
import { Grid, TextField, Box, InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ProfileServiceClass from "../Services/Candidate/profile.service";
import { success, danger } from "../Components/shared/messageBox";
import ApiCalls from "../api/customAxios";
import LocationIcon from "../assets/images/filter-location-icon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import _ from "lodash";
const Constant = require("../helpers/constants");

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullAddress: props.country
        ? `${props?.city}${
            props?.city && (props?.state || props?.country) ? ", " : ""
          }${props?.state}${props?.state && props?.country ? ", " : ""}${
            props?.country
          }`
        : "",
      city: props.city ? props.city : "",
      isDirty: false,
      openLocationsAutoComplete: false,
      locationsAutoFill: [],
      clear: false,
    };
    this.debouncedHandler = _.debounce(this.handleDebounceInput.bind(this), Constant.DEBOUNCE_TIME);
    document.addEventListener("clearLocation", () => {
      if (this.props.city) {
        this.props.onSelectLocation({
          city: "",
          state: "",
          country: "",
        });
        this.setState({ city: "" });
      }
    });
  }
  handleDebounceInput = (e) => {
    this.getLocations(e.target.value);
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.city !== this.props.city) {
      if (this.props.city === "") {
        this.props.onSelectLocation({
          city: "",
          state: "",
          country: "",
        });
        this.setState({ city: "" });
      }
      if (this.props.city) {
        this.setState({ city: this.props.city });
      }
    }
  }
  handleChange = (city) => {
    if (city === "") {
      this.props.onSelectLocation({
        city: "",
        state: "",
        country: "",
      });
    }
    this.setState({ city });
    this.setState({ isDirty: true });
  };

  handleSelect = (location) => {
    let locationList = location != undefined && location.split(",");
    let shortenstate = "";

    if (locationList.length > 2) {
      shortenstate = locationList[1]?.trim();
    } else {
      shortenstate = locationList[0]?.trim();
    }
    if (location != undefined) {
      let Url;
      if (this.props.isAuthenticated) {
        Url = process.env.REACT_APP_API_URL + "places/";
      } else {
        Url =
          process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
          "places/";
      }
      ApiCalls.get(Url + location + "?type=D")
        .then((results) => {
          const addressComponents = results[0].addressComponents;
          let stateShort = addressComponents.filter(function (
            addressComponent
          ) {
            return addressComponent.types.includes(
              "ADMINISTRATIVE_AREA_LEVEL_1"
            );
          });
          let countryShort = addressComponents.filter(function (
            addressComponent
          ) {
            return addressComponent.types.includes("COUNTRY");
          });
          let locationDetails;
          if (locationList.length > 2) {
            locationDetails = {
              city: locationList[0]?.trim(),
              state: stateShort[0].shortName,
              country: countryShort[0].shortName,
            };
          } else {
            locationDetails = {
              city: locationList[0]?.trim(),
              state: stateShort[0].shortName,
              country: countryShort[0].shortName,
            };
          }
          this.props.onSelectLocation(locationDetails);
          if (locationList.length > 2) {
            this.setState({
              city: `${locationList[0].trim()}, ${stateShort[0].shortName}, ${
                countryShort[0].shortName
              }`,
            });
          } else {
            this.setState({
              city: `${locationList[0].trim()}, ${stateShort[0].shortName}, ${
                countryShort[0].shortName
              }`,
            });
          }
        })
        .catch(() => {
          if (locationList.length > 2) {
            this.setState({
              city: `${locationList[0].trim()}, ${locationList[1].trim()}, ${locationList[2].trim()}`,
            });
          } else {
            this.setState({
              city: `${locationList[0].trim()}, ${locationList[1].trim()}`,
            });
          }
        });
      this.setState({ isDirty: false });
    }
  };
  getLocations = (event) => {
    let Url;
    if (this.props.isAuthenticated) {
      Url = process.env.REACT_APP_API_URL + "places/";
    } else {
      Url =
        process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
        "places/";
    }
    this.handleChange(event);
    if (!event) {
      if (this.props.clearFilters) {
        this.props.clearFilters(true);
      }
      this.setState({ locationsAutoFill: [] });
    } else if (event.length >= 1) {
      ApiCalls.get(
        Url + event + (this.props.stateCheck ? "?type=R" : "")
      )
        .then((res) => {
          this.setState({ locationsAutoFill: res });
        })
        .catch(function (error) {});
    }
  };

  render() {
    const options = {
      types: ["(cities)"],
    };
    // const onError = (status, clearSuggestions) => {
    //   clearSuggestions();
    // };
    return (
      // <PlacesAutocomplete
      //   value={this.state.city}
      //   onChange={this.handleChange}
      //   onSelect={this.handleSelect}
      //   searchOptions={options}
      //   onError={onError}
      // >
      //   {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
      //     let value = getInputProps().value;
      //     if (this.state.city !== "") {
      //       value = this.state.city;
      //     }
      //     if (this.state.city == "" && getInputProps().value != "") {
      //       value = "";
      //     }
      //     return (
      //       <div className="city-placeholder">
      //         <input
      //           {...getInputProps({
      //             placeholder: this.props.placeholder
      //               ? this.props.placeholder
      //               : "Enter city",
      //             className: "location-search-input citySection inputcontrasts",
      //             id: "inputCity",
      //           })}
      //           onKeyDown={
      //             this.props.onKeyDown ? this.props.onKeyDown : () => {}
      //           }
      //           onKeyPress={
      //             this.props.onKeyDown ? this.props.onKeyDown : () => {}
      //           }
      //           onBlur= {(e) => {
      //             if (this.state.isDirty) {
      //               e.target.value = '';
      //               if (document.getElementsByClassName('autocomplete-dropdown-container')) {
      //                 document.getElementsByClassName('autocomplete-dropdown-container')[0].style.display = 'none';
      //               }
      //             }
      //           }}
      //           // value={value}
      //         />
      //         <div className="autocomplete-dropdown-container">
      //           {loading && <div>Loading...</div>}
      //           {suggestions.map((suggestion) => {
      //             const className = suggestion.active
      //               ? "suggestion-item--active"
      //               : "suggestion-item";
      //             // inline style for demonstration purpose
      //             const style = suggestion.active
      //               ? {
      //                   backgroundColor: "#fafafa",
      //                   cursor: "pointer",
      //                   paddingTop: "15px",
      //                   fontSize: "14px",
      //                 }
      //               : {
      //                   backgroundColor: "#ffffff",
      //                   cursor: "pointer",
      //                   paddingTop: "15px",
      //                   fontSize: "14px",
      //                 };
      //             return (
      //               <div
      //                 key="key"
      //                 {...getSuggestionItemProps(suggestion, {
      //                   className,
      //                   style,
      //                 })}
      //               >
      //                 <span>{suggestion.description}</span>
      //               </div>
      //             );
      //           })}
      //         </div>
      //       </div>
      //     );
      //   }}
      // </PlacesAutocomplete>
      <Autocomplete
        onBlur={()=>{
          if (this.state.isDirty) {
            this.setState({ city: "" });
          }
        }}
        open={this.state.openLocationsAutoComplete}
        onClose={() => this.setState({ openLocationsAutoComplete: false })}
        key={this.props.addLocation ? this.state.clear : ""}
        id="free-solo-demo"
        options={this.state.locationsAutoFill}
        popupIcon={""}
        className="location-shorten filter-location"
        noOptionsText={
          <span className="gray9 f-14 font-weight-400">
            Please select from the list
          </span>
        }
        getOptionLabel={(option) =>
          option.description ? option.description : this.state.city
        }
        value={
          this.state.fullAddress
            ? this.state.fullAddress
            : this.state.city
            ? this.state.city
            : ""
        }
        isOptionEqualToValue={(option, value) =>
          option.description[0].trim() === value
        }
        onInputChange={(_, value) => {
          if (value.length === 0) {
            if (this.state.openLocationsAutoComplete) {
              this.setState({ openLocationsAutoComplete: false });
            }
          } else {
            if (!this.state.openLocationsAutoComplete) {
              this.setState({ openLocationsAutoComplete: true });
            }
          }
          if (value == this.state.city) {
            this.setState({ openLocationsAutoComplete: false });
          }
        }}
        clearIcon={
          <>
            {this.props.city !== "" && (
              <div
                className="cursor-hover display-flex align-center"
                onClick={this.props.handleLocationClear}
              >
                <ClearIcon />
              </div>
            )}
          </>
        }
        renderInput={(params) => (
          <TextField
            {...params}
            type="text"
            className="form-control input-group inputcontrasts position-issue inputcontrasts edit-skill-align mt-10 p-0 search-job m-b-0 location-search-input citySection search-radius"
            margin="normal"
            placeholder={
              this.props.placeholder ? this.props.placeholder : "Enter location"
            }
            onChange={this.debouncedHandler}
            style={{ borderRadius: "0px" }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start" aria-label="Search icon">
                    <img src={LocationIcon} alt="img" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
        onChange={(event, address) =>
          this.handleSelect(address?.description, address?.placeId)
        }
        ListboxProps={{
          style: {
            maxHeight: "10rem",
            position: "absolute",
            top: "13px",
            backgroundColor: "white",
            zIndex: "999999999",
            width: "100%",
          },
        }}
      />
    );
  }
}

export default LocationSearchInput;

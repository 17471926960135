import { React } from 'react';
import { Box, Grid, Typography } from "@mui/material";

const OptionsWidget = (props) => {
    return (
        <Grid container spacing={1} className='mt-10 display-flex align-center'>
            {props.options?.map((option, index)=>(
                <Grid item key={index}>
                    <Typography
                        className='f-16 font-weight-400 chat-options'
                        onClick={(e)=>props.callBlipService(option.action, option.button_caption)}
                    >
                        {option.button_caption}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
};
export default OptionsWidget;

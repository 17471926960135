import React, { useEffect, useState } from 'react';
import ChatWindow from './ChatWindow';
import MessageChatBox from './MessageChatBox';

const ChatContainer = (props) => {
    const [chats, setChats] = useState([]);
    const [openSmsChat, setOpenSmsChat] = useState(false);
    const [openNewMessageChat, setOpenNewMessageChat] = useState(false);
    const [isBlipChatbotOpen, setIsBlipChatbotOpen] = useState(false);
    const [isChatbotGreetingOpen, setIsChatbotGreetingOpen] = useState(false);
    const [candidatePolicy, setCandidatePolicy] = useState("");
    const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

    function getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    useEffect(()=>{
        if (localStorage.getItem("PolicyAcceptanceStatus")) {
            setCandidatePolicy(localStorage.getItem("PolicyAcceptanceStatus"));
        }
    }, [localStorage.getItem("PolicyAcceptanceStatus")]);

    useEffect(()=>{
        let _chats = localStorage.getItem("SMS_CHATS");
        if (_chats) {
            setChats(JSON.parse(_chats));
        }
        const handleStorageUpdate = () => {
            if (localStorage.getItem("isBlipChatbotOpen") || localStorage.getItem("isPreqChatbotOpen")) {
                setIsBlipChatbotOpen(true);
            } else {
                setIsBlipChatbotOpen(false);
            }
            if (!getCookie("isChatbotBeforeGreetingMsgClosed")) {
                setIsChatbotGreetingOpen(true);
            } else {
                setIsChatbotGreetingOpen(false);
            }
            if (localStorage.getItem("isOpenSideDrawer")) {
                setIsSideDrawerOpen(true);
            } else {
                setIsSideDrawerOpen(false);
            }
            let _chats = localStorage.getItem("SMS_CHATS");
            if (_chats) {
                setChats(JSON.parse(_chats));
            } else {
                setChats([]);
            }
            setOpenSmsChat(localStorage.getItem("OPEN_SMS_CHAT") ? true : false);
        };
        const intervalId = setInterval(handleStorageUpdate, 1000); // Check every second
        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={`chat-container ${isBlipChatbotOpen ? "messages-chat-align" : ""} ${props.isFromPublicProfile ? "public-profile-message-container" : ""}`}>
            {chats.map((candidate, index) => (
                <ChatWindow key={candidate?.id + candidate?.enterpriseId} index={index} isLastChatWindow={index === 0} openSmsChat={openSmsChat} candidate={candidate} />
            ))}
            <MessageChatBox
                setOpenNewMessageChat={setOpenNewMessageChat}
            />
        </div>
    );
};

export default ChatContainer;
